@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	contact
/--------------------------------------------------------------------*/

.contact-cont {
	max-width: 1200px;
	@include m-a;
}

.contact-flex {
	display: flex;
	margin-top: clamp(4em, 4vw, 4em);
	@include media-breakpoint-up(md) {
		justify-content: space-between;
		& > * {
			flex-basis: 48.5%;
		}
	}
	@include media-breakpoint-up(xl) {
		& > * {
			flex-basis: 47.5%;
		}
	}
	@include media-breakpoint-down(sm) {
		flex-direction: column;
		row-gap: clamp(3em, 4vw, 4em);
	}
}

.contact-box {
	text-align: center;
	padding: clamp(1.5em, 2vw, 2em) 4% clamp(2em, 2.5vw, 2.5em);
	background: $other_c2;
	position: relative;
	@include radius_m;
	@include media-breakpoint-up(md) {
		padding: clamp(2em, 3vw, 3em) 2em 3rem;
	}
	@include media-breakpoint-up(xl) {
		padding: clamp(2em, 3vw, 3em) 4.5em 3rem;
	}
	&__icon {
		display: flex;
		justify-content: center;
		color: $white;
		margin-bottom: 1.5em;
		@include m-a;
		@include f-w(100);
		& > * {
			position: absolute;
			bottom: calc(100% - 1.5em);
			display: flex;
			justify-content: center;
			align-items: center;
			width: 60px;
			height: 60px;
			@include f-size(24);
			@include radius_s;
			background: $main_c;
			@include media-breakpoint-up(md) {
				@include f-size(30);
				width: 80px;
				height: 80px;
			}
		}
	}
	&__tit {
		margin-bottom: clamp(1em,1.5vw,1.5em);
		@include f-s_xxs(1.14, 8);
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1.25, 6);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(28);
		}
	}
	&__tel {
		@include f-family(font02);
		@include f-s_xxs(1.5,12);
		@include media-breakpoint-up(md) {
			@include f-s_md(1.75,12);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(40);
		}
		i {
			font-size: 0.75em;
			margin-right: 0.3em;
			color: $main_c;
		}
		a {
			color: $txt_c;
		}
	}
}

.contact-box__line {
	display: grid;
	grid-template-columns: 36% 1fr;
	grid-template-rows: auto auto;
	gap: 1em 5%;
	max-width: 400px;
	@include m-a;
	@include media-breakpoint-up(md) {
		@include f-s_md(0.7,4);
	}
	@include media-breakpoint-up(xl) {
		font-size: 1em;
	}
	&__img {
		grid-column: 1 / 2;
		grid-row: 1 / 3;
	}
	&__btn {
		grid-column: 2 / 3;
		grid-row: 1 / 2;
		a {
			display: block;
			width: 100%;
			height: 100%;
			padding: .5em 5%;
			background: $line_c;
			@include dec-none;
			color: $white;
			@include f-w(700);
			@include radius;
			font-size: 1.25em;
			@include media-breakpoint-up(xs) {
				font-size: 1.5em;
			}
			i {
				@include f-w(400);
				margin-right: 0.2em;
			}
		}
	}
	&__id {
		grid-column: 2 / 3;
		grid-row: 2 / 3;
		&__inner {
			width: 100%;
			padding: .5em 10%;
			background: $white;
			display: flex;
			align-items: baseline;
			column-gap: 0.5em;
			font-size: 1em;
			@include radius;
			@include media-breakpoint-up(xs) {
				font-size: 1.37em;
			}
			b {
				@include f-family(font02);
				@include l-sp(0);
				color: $line_c;
				@include f-w(700);
				font-size: 1.2em;
			}
			span {
				@include f-w(700);
			}
		}
	}
}

.contact-mail {
	margin-top: clamp(3em, 4vw,4em);
	margin-bottom: clamp(4em,6vw,6em);
	text-align: center;
	padding: clamp(1.5em, 2vw, 2em) 4% clamp(2em, 2.5vw, 2.5em);
	background: $bg_c1;
	position: relative;
	@include radius_m;
	@include media-breakpoint-up(md) {
		margin-top: clamp(4em, 5vw, 5em);
		padding: clamp(2em, 3vw, 3em) 3rem 3rem;
	}
	@include media-breakpoint-up(xl) {
		padding: clamp(2em, 3vw, 3em) 4.5em 3rem;
	}
	&__icon {
		display: flex;
		justify-content: center;
		color: $white;
		margin-bottom: 1.5em;
		@include m-a;
		@include f-w(100);
		& > * {
			position: absolute;
			bottom: calc(100% - 1.5em);
			display: flex;
			justify-content: center;
			align-items: center;
			width: 60px;
			height: 60px;
			@include f-size(24);
			@include radius_s;
			background: $point_c;
			@include media-breakpoint-up(md) {
				@include f-size(30);
				width: 80px;
				height: 80px;
			}
		}
	}
	&__tit {
		margin-bottom: clamp(1em,1.5vw,1.5em);
		@include f-s_xxs(1.14, 12);
		@include media-breakpoint-up(xl) {
			@include f-size(28);
		}
	}
}

/*--------------------------------------------------------------------/
	contact form
/--------------------------------------------------------------------*/

.common-form {
	max-width: 960px;
	@include m-a;
	&__tit {
		margin-bottom: clamp(2rem, 3vw, 3rem);
		b {
			@include f-family(font02);
			color: $main_c;
			@include f-w(500);
			text-align: center;
			@include f-s_xxs(1.75, 20);
			@include l-sp(0);
			display: block;
			@include media-breakpoint-up(xl) {
				@include f-size(48);
			}
		}
		span {
			display: block;
			text-align: center;
			@include f-w(700);
			@include f-s_xxs(1, 8);
			@include media-breakpoint-up(xl) {
				@include f-size(24);
			}
		}
	}
}

.contact-step {
	& ol {
		counter-reset: contact-num;
		display: flex;
		@include media-breakpoint-up(lg) {
			max-width: 1040px;
			@include m-a;
			align-items: center;
			justify-content: space-between;
			& > * {
				width: 18%;
				max-width: 160px;
				max-height: 160px;
			}
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
		}
		& li {
			color: $txt_c;
			position: relative;
			@include f-size(14);
			@include media-breakpoint-up(lg) {
				width: 160px;
				height: 160px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid $gray;
				text-align: center;
				background: $white;
				@include radius(50%);
			}
			@include media-breakpoint-down(md) {
				&:not(:last-child) {
					margin-bottom: 1rem;
				}
			}
			span {
				@include f-w(700);
				@include media-breakpoint-up(lg) {
					&::before {
						@include f-size(36);
					}
				}
				@include media-breakpoint-down(md) {
					display: flex;
					align-items: center;
					@include f-em(18);
					&::before {
						width: 60px;
						height: 60px;
						display: flex;
						margin-right: 1em;
						align-items: center;
						justify-content: center;
						border: 1px solid $point_c;
						text-align: center;
						background: $white;
						@include f-size(20);
						@include radius(50%);
					}
				}
				&::before {
					display: block;
					@include f-family(font01);
					counter-increment: contact-num 1;
					content: counter(contact-num, decimal-leading-zero);
					line-height: 1;
					margin-bottom: 0.3rem;
					color: $point_c;
				}
			}
			&:not(:last-child)::after {
				position: absolute;
				content: "";
				background: $gray;
				@include media-breakpoint-up(lg) {
					left: 100%;
					width: 100%;
					height: 1px;
				}
				@include media-breakpoint-down(md) {
					top: 60px;
					left: 30px;
					width: 1px;
					height: 100%;
				}
			}
			&.now {
				@include media-breakpoint-up(lg) {
					background: $point_c;
					color: $white;
					border-color: $point_c;
				}
				& span {
					&::before {
						color: $white;
						@include media-breakpoint-down(md) {
							background: $point_c;
						}
					}
				}
			}
			&.before {
				@include media-breakpoint-up(lg) {
					background: $other_c6;
					color: $white;
					border-color: $other_c6;
				}
				& span {
					&::before {
						color: $white;
						@include media-breakpoint-down(md) {
							background: $other_c6;
						}
					}
				}
				&::after {
					@include media-breakpoint-up(lg) {
						border-color: transparent transparent transparent $other_c6;
					}
					@include media-breakpoint-down(md) {
						border-color: $other_c6 transparent transparent transparent;
					}
				}
			}
		}
	}
}

.contact-privacy {
	border: 1px solid $gray;
	@include media-breakpoint-up(lg) {
		padding: clamp(1.5rem, 3vw, 3rem) 5% clamp(2rem, 3.5vw, 3rem);
		margin-top: 1.5rem;
		overflow-y: scroll;
		@include scroll-bar;
		max-height: 300px;
	}
	&__inner {
		@include media-breakpoint-up(lg) {
			margin-top: 1.5rem;
			padding: 0 5%;
		}
	}
	&__tit {
		@include f-s_xs(1.14, 4);
		@include f-w(700);
		text-align: center;
		padding-bottom: clamp(1rem, 1.5vw, 1.5rem);
		position: relative;
		@include media-breakpoint-up(lg) {
			margin-bottom: clamp(1rem, 2vw, 2rem);
			&::before {
				content: "";
				position: absolute;
				width: 4rem;
				height: 3px;
				bottom: 0;
				left: calc(50% - 2rem);
				border-radius: 2px;
				background: $main_c;
			}
			.privacy-btn {
				display: none;
			}
		}
		@include media-breakpoint-up(xl) {
			@include f-size(22);
		}
		@include media-breakpoint-down(md) {
			padding: 1.5rem 6%;
			position: relative;
			.privacy-btn {
				cursor: pointer;
				display: block;
				position: absolute;
				top: calc(50% - 16px);
				right: 1rem;
				width: 32px;
				height: 32px;
				@include transition;
				i {
					@include f-size(32);
					@include line-h(1);
					color: $gray;
				}
				&.active {
					transform: rotate(180deg);
				}
			}
		}
	}
	&__txt {
		@include media-breakpoint-down(md) {
			padding: 2em 5% clamp(2rem, 3.5vw, 3.5rem);
		}
	}
	.txt {
		@include f-em(15);
	}
}

.contact-table {
	width: 100%;
	@include m-a;
	margin-bottom: 2rem;
	line-height: 1.2;
	position: relative;
	& th,
	& td {
		text-align: left;
		@include media-breakpoint-up(lg) {
			display: table-cell;
			padding: 1rem 0 1.5rem;
		}
		@include media-breakpoint-down(md) {
			display: block;
			padding: 0;
		}
	}
	& th {
		vertical-align: top;
		@include f-w(500);
		@include media-breakpoint-up(lg) {
			width: 35%;
			line-height: 1.5;
		}
		& span {
			color: $white;
			margin-left: 0.3rem;
			padding: 0.2em 0.75em;
			font-size: 0.8125em;
		}
	}
	& td {
		@include media-breakpoint-up(lg) {
			width: auto;
		}
		@include media-breakpoint-down(md) {
			padding: 1em 0 2em;
		}
	}
	& textarea {
		width: 100%;
	}
}

input,
select {
	width: auto;
	&.long {
		width: 100%;
	}
	&.middle {
		width: 100%;
	}
	&.short {
		width: 70%;
		@include media-breakpoint-up(md) {
			width: 30%;
		}
	}
	&.mini {
		width: 6em;
	}
}

button {
	outline: none;
	font-size: 1em;
	@include l-sp(0.1em);
}

button[class*="sub-btn"],
button[class*="back-btn"],
button[class*="chack-btn"] {
	font-size: 1em;
	@include l-sp(0.1em);
}
button[class*="sub-btn"] {
	@include btn_arrow($white, $main_c, $main_c, $txt_c, $white, $point_c, $point_c,$point_c);
}
button[class*="back-btn"] {
	margin-top: 1em;
	@include btn_arrow($d-gray, $l-gray, $l-gray, $d-gray, $white, $black, $black, $black);
}
button[class*="chack-btn"] {
	@include btn_arrow($white, $main_c, $main_c, $txt_c, $white, $point_c, $point_c,$point_c);
}

%form-btn {
	width: 100%;
	max-width: 240px;
	margin: 0 auto;
	display: block;
	margin-top: 2rem;
	padding: 0.5rem 2rem;
	@include transition;
	cursor: pointer;
	font-size: 0.94em;
	@include media-breakpoint-up(full) {
		max-width: floor-decimal(calc(240 / 1920), 4) * 100vw;
	}
}

[type="button"],
[type="submit"] {
	width: 100%;
	max-width: 320px;
	margin: 0 auto;
	display: block;
	margin-top: 1rem;
	@include transition;
	cursor: pointer;
	outline: none;
	@include media-breakpoint-up(full) {
		max-width: floor-decimal(calc(320 / 1920), 4) * 100vw;
	}
}

/* radio-box,check-box
----------------------------------------------------------------*/
/* variablea */
$radio_height: 20px; //チェックボックスの高さ
$duration_radio: 0.4s; //チェックボックスの「「アニメーションのスピード
$checkbox_height: 26px; //チェックボックスの高さ
$duration_checkbox: 0.4s; //チェックボックスの「「アニメーションのスピード

/* radio-btn */
.mwform-radio-field {
	cursor: pointer;
	&:not(:last-child) {
		margin-right: 1em;
	}
}
.mwform-radio-field-text {
	vertical-align: top;
	position: relative;
	padding-left: 34px;
	display: block;
}
input[type="radio"] {
	position: absolute;
	opacity: 0;
	+ .mwform-radio-field-text {
		&:before {
			content: "";
			background: $o-white;
			border-radius: 100%;
			border: 1px solid $gray;
			display: inline-block;
			width: $radio_height;
			height: $radio_height;
			position: absolute;
			top: calc(50% - calc(#{$radio_height}/ 2));
			left: 0;
			vertical-align: top;
			cursor: pointer;
			text-align: center;
			transition: all $duration_radio ease;
		}
	}
	&:checked {
		+ .mwform-radio-field-text {
			&:before {
				background-color: $main_c;
				box-shadow: inset 0 0 0 5px $p-gray;
			}
		}
	}
	&:focus {
		+ .mwform-radio-field-text {
			&:before {
				outline: none;
				border-color: $main_c;
			}
		}
	}
	&:disabled {
		+ .mwform-radio-field-text {
			&:before {
				box-shadow: inset 0 0 0 4px $gray;
				border-color: $gray;
				background: $o-white;
			}
		}
	}
	+ .mwform-radio-field-text {
		&:empty {
			&:before {
				margin-right: 0;
			}
		}
	}
}
.vertical-item:not(:first-of-type) {
	margin-top: 1rem !important;
}

/* chack-btn */
@-moz-keyframes dothabottomcheck {
	0% {
		height: 0;
	}
	100% {
		height: calc($checkbox_height/2);
	}
}

@-webkit-keyframes dothabottomcheck {
	0% {
		height: 0;
	}
	100% {
		height: calc($checkbox_height/2);
	}
}

@keyframes dothabottomcheck {
	0% {
		height: 0;
	}
	100% {
		height: calc($checkbox_height/2);
	}
}

@keyframes dothatopcheck {
	0% {
		height: 0;
	}
	50% {
		height: 0;
	}
	100% {
		height: $checkbox_height * 1.2;
	}
}
@-webkit-keyframes dothatopcheck {
	0% {
		height: 0;
	}
	50% {
		height: 0;
	}
	100% {
		height: $checkbox_height * 1.2;
	}
}
@-moz-keyframes dothatopcheck {
	0% {
		height: 0;
	}
	50% {
		height: 0;
	}
	100% {
		height: $checkbox_height * 1.2;
	}
}

input[type="checkbox"] {
	display: none;
}

.check-box {
	& .vertical-item:not(:first-of-type) {
		margin-top: 1.5rem !important;
	}
	& label {
		position: relative;
		cursor: pointer;
		&::before {
			content: "";
			height: $checkbox_height;
			width: $checkbox_height;
			background-color: transparent;
			border: 1px solid $gray;
			margin-right: 1em;
			position: absolute;
			top: -0.15em;
			left: 0;
			display: block;
			transition: border-color ease calc($duration_checkbox/2);
			z-index: z-index(module, part04);
		}
	}
}

.mwform-checkbox-field-text {
	vertical-align: top;
	padding-left: calc(#{$checkbox_height} + 1rem);
	display: block;
	&::before,
	&::after {
		position: absolute;
		height: 0;
		width: $checkbox_height * 0.2;
		background-color: $main_c;
		display: inline-block;
		transform-origin: left top;
		content: "";
		transition: opacity ease 0.5;
		z-index: z-index(module, part03);
	}
	&::before {
		top: $checkbox_height * 0.86;
		left: $checkbox_height * 0.4;
		transform: rotate(-135deg);
	}
	&::after {
		top: $checkbox_height * 0.5;
		left: $checkbox_height * 0.03;
		transform: rotate(-45deg);
	}
}

input[type="checkbox"]:checked + .mwform-checkbox-field-text,
.mwform-checkbox-field-text.checked {
	border-color: $main_c;
	&::after {
		height: $checkbox_height * 0.5;
		animation: dothabottomcheck calc($duration_checkbox/2) ease 0s forwards;
	}

	&::before {
		height: $checkbox_height * 1.2;
		animation: dothatopcheck $duration_checkbox ease 0s forwards;
		box-shadow: 0 0 0 $checkbox_height * 0.05 $white;
	}
}

.mw_wp_form .error {
	margin-top: 0.7rem;
}

/* form-parts
----------------------------------------------------------------*/
%form-parts {
	background-color: $o-white;
	padding: 0.8em 1em;
	border: 1px solid $m-gray;
	line-height: 1.5;
	&:focus {
		background-color: $white;
		box-shadow: rgba(58, 134, 232, 0.25) 0 0 4px 1px;
	}
}

%placeholder {
	color: $gray;
}

input {
	@extend %form-parts;
	&::-webkit-input-placeholder {
		@extend %placeholder;
	}
	&:-moz-placeholder {
		@extend %placeholder;
	}
	&:-ms-input-placeholder {
		@extend %placeholder;
	}
}

textarea {
	@extend %form-parts;
	height: 10em;
}
select {
	@extend %form-parts;
}
