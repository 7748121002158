@charset "utf-8";
@use "_mixins/break-points" as *;
@use "base" as *;
/*====================================================================

lv2.css

=====================================================================*/
@use "_components/breadcrumb"; //パンくずリスト
@use "_lv2/about"; //私たちの想い
@use "_lv2/service"; //サービス内容
@use "_lv2/l-sketch"; //ライフスケッチ®
@use "_lv2/company"; //会社案内
@use "_lv2/contact"; //お問い合わせ
@use "_lv2/aside"; //サイドバー

/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
	position: relative;
	background: $bg_c7;
	@include wave_white_bottom;
	height: clamp(240px, 36vw, 320px);
	@include media-breakpoint-up(md) {
		height: clamp(320px, 30vw, 370px);
	}
	@include media-breakpoint-up(xl) {
		height: 370px;
	}
	@include media-breakpoint-up(full) {
		height: 19.27vw;
	}
	&__add01 {
		position: absolute;
		width: 20.15%;
		top: clamp(90px, 14vw, 140px);
		left: 2%;
		@include media-breakpoint-up(md) {
			top: clamp(100px, 10vw, 140px);
			left: 3%;
		}
		@include media-breakpoint-up(xl) {
			top: 5rem;
			right: 72%;
		}
	}
	&__add02 {
		position: absolute;
		width: 18.38%;
		top: clamp(90px, 14vw, 140px);
		right: 2%;
		@include media-breakpoint-up(md) {
			top: 13vw;
			top: clamp(100px, 13vw, 160px);
			right: 4%;
		}
		@include media-breakpoint-up(xl) {
			top: 7.5rem;
			left: 70%;
		}
	}
	& .l-cont {
		position: relative;
		height: 100%;
	}
	&__tit {
		text-align: center;
		position: absolute;
		top: 50%;
		right: 0;
		left: 0;
		color: $sub_c;
		transform: translateY(-50%);
		@include f-w(700);
		@include media-breakpoint-up(xl) {
			top: 48%;
		}
		h1 {
			@include f-s_xxs(1.25, 28);
			@include f-w(700);
			line-height: 1.4;
			@include media-breakpoint-up(xl) {
				@include f-size(46);
			}
			& span.is-type {
				display: block;
				@include f-s_xxs(1, 10);
				max-width: 1200px;
				@include m-a;
				@include media-breakpoint-up(xl) {
					@include f-size(24);
				}
			}
			&.is-single {
				padding-top: 1em;
				@include f-s_xxs(1.14, 14);
				text-shadow: 0 0 10px $white;
				max-width: 1200px;
				@include m-a;
				@include media-breakpoint-up(xl) {
					@include f-size(32);
				}
			}
			&.is-404 {
				@include f-family(font02);
				@include f-w(500);
				@include f-s_xxs(1.25, 28);
				@include media-breakpoint-up(xl) {
					@include f-size(48);
				}
			}
		}
		&__category {
			margin-top: 0.5em;
			@include flex-wrap;
			gap: 0.5em;
			justify-content: center;
			li {
				span {
					@include media-breakpoint-down(xs) {
						font-size: 0.8em;
					}
				}
			}
		}
	}
}

/*--------------------------------------------------------------------/
	contents
/--------------------------------------------------------------------*/
.contents {
	padding-top: 1em;
}

/*--------------------------------------------------------------------/
	common-flex
/--------------------------------------------------------------------*/

// common-flex
$common-main-w-lg: 72%; //メイン横幅%
$common-aside-w-lg: 25%; //アサイド横幅%
$common-main-w-xl: 74.16%; //メイン横幅%
$common-aside-w-xl: 20.83%; //アサイド横幅%

.common-flex {
	width: 100%;
	position: relative;
	display: flex;
	@include media-breakpoint-up(lg) {
		justify-content: space-between;
	}
	@include media-breakpoint-down(md) {
		flex-direction: column;
		row-gap: clamp(3rem, 5vw, 5rem);
	}
	&__main {
		@include media-breakpoint-up(lg) {
			width: $common-main-w-lg;
		}
		@include media-breakpoint-up(xl) {
			width: $common-main-w-xl;
		}
	}
	&__aside {
		position: relative;
		@include media-breakpoint-up(lg) {
			width: $common-aside-w-lg;
		}
		@include media-breakpoint-up(xl) {
			width: $common-aside-w-xl;
		}
		@include media-breakpoint-down(md) {
			width: 100%;
		}
	}
}

.blog-popular {
	padding-top: clamp(2em, 3vw, 3em);
	padding-bottom: clamp(3em, 6vw, 6em);
	margin-bottom: clamp(3em, 6vw, 6em);
	border-bottom: 1px solid $l-gray;
	&__tit {
		position: relative;
		text-align: center;
		margin-bottom: clamp(3em, 2.5vw, 3em);
		color: $sub_c;
		@include f-s_xxs(1.25, 12);
		@include f-w(700);
		@include media-breakpoint-up(md) {
			margin-bottom: clamp(2em, 2.5vw, 3em);
		}
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1.75, 8);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(36);
		}
		&::before {
			position: absolute;
			left: 50%;
			bottom: calc(100% + 0.3em);
			transform: translateX(-50%);
			content: "";
			width: clamp(50px, 7.3vw, 74px);
			height: clamp(39px, 5.7vw, 58px);
			background: url(../img/common/icon_crown.svg) center center/contain no-repeat;
		}
	}

	.blog-box__popular {
		span::after {
			@include media-breakpoint-up(lg) {
				font-size: clamp(2em, 2.5vw, 54px);
			}
		}
	}
}

.common-flex__list {
	display: flex;
	&.is-col1 {
		flex-direction: column;
		row-gap: 2em;
		@include media-breakpoint-up(md) {
			row-gap: clamp(1em, 1.5vw, 1.5em);
		}
	}
	&.is-col3 {
		display: grid;
		row-gap: 2em;
		@include media-breakpoint-up(sm) {
			grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
			column-gap: 4%;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
		@include media-breakpoint-up(md) {
			grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
			column-gap: 2%;
		}
		@include media-breakpoint-up(lg) {
			grid-template-columns: repeat(auto-fit, minmax(260px, 32.33%));
			column-gap: 1.5%;
		}
		@include media-breakpoint-up(xl) {
			grid-template-columns: repeat(auto-fit, minmax(260px, 31.46%));
			column-gap: 2.8%;
		}
		&.popular-list {
			row-gap: 4.5em;
			@include media-breakpoint-up(sm) {
				row-gap: clamp(3em, 4vw, 4em);
			}
		}
	}
}

/*--------------------------------------------------------------------/
	single-cont
/--------------------------------------------------------------------*/
.single-cont {
}
.single-cont__main {
	padding-bottom: clamp(3em, 5vw, 5em);
	border-bottom: 1px dashed $main_c;
	&__img {
		@include radius;
		overflow: hidden;
	}
	&__textbox {
		padding-top: clamp(3em, 5vw, 5em);
		&__tit {
			position: relative;
			padding-left: 1.5em;
			color: $sub_c;
			@include f-w(700);
			@include line-h(1.4);
			margin-bottom: clamp(0.75em, 1vw, 1em);
			@include f-s_xxs(1.14, 8);
			@include media-breakpoint-up(lg) {
				@include f-s_md(1.25, 4);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(26);
			}
			&::before {
				position: absolute;
				top: 0.6em;
				left: 0;
				content: "";
				width: 1em;
				height: 0.2em;
				background: $sub_c;
				@include radius(999px);
			}
		}
	}
}

.single-cont__canva {
	padding: clamp(2.5em, 3vw, 3rem) 0 clamp(3em, 4vw, 4rem);
	border-bottom: 1px dashed $main_c;
}

// single-cont__pdf
.single-cont__pdf {
	padding: clamp(2.5em, 3vw, 3rem) 0 clamp(3em, 4vw, 4rem);
	&__list {
		display: flex;
		@include media-breakpoint-up(lg) {
			flex-wrap: wrap;
			column-gap: 2%;
			row-gap: 1em;
			& > * {
				width: 49%;
			}
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: 0.5em;
		}
		& li {
			a {
				padding: 1.25em 4% 1.25em 4.5em;
				@include transition;
				position: relative;
				width: 100%;
				height: 100%;
				display: block;
				@include line-h(1.4);
				color: $txt_c;
				background: $other_c2;
				@include dec-none;
				@include f-w(700);
				@include radius(999px);
				@include icon(pdf);
				&::before {
					position: absolute;
					left: 1em;
					top: 0.6em;
					font-size: 1.8em;
					color: $sub_c;
					@include transition;
				}
				&:hover {
					background: $hover_c;
					color: $white;
					&::before {
						color: $white;
					}
				}
			}
		}
	}
}

// single-cont__interview
$single_interview_txt_w_md: 55%; //テキストの横幅 md
$single_interview_txt_w_lg: 51.25%; //テキストの横幅 lg
$single_interview_img_w_md: 40%; //画像の横幅 md
$single_interview_img_w_lg: 42.87%; //画像の横幅 lg
.single-cont__interview {
	padding-bottom: clamp(3rem, 7vw, 7rem);
	&__list {
		@include flex-column;
		row-gap: clamp(3em, 5vw, 5em);
	}
}

.single-cont__interview__box {
	&__flex {
		display: flex;
		@include media-breakpoint-up(md) {
			justify-content: space-between;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: 1em;
		}
		&.is-right {
			@include media-breakpoint-up(md) {
				flex-direction: row;
			}
		}
		&.is-left {
			@include media-breakpoint-up(md) {
				flex-direction: row-reverse;
			}
		}
	}
	&__tit {
		position: relative;
		@include f-s_xxs(1.14, 8);
		padding-bottom: 0.7em;
		margin-bottom: calc(0.5rem + 0.75em);
		@include line-h(1.5);
		@include f-w(700);
		@include media-breakpoint-up(lg) {
			@include f-s_md(1.14, 6);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
		&::before {
			content: "";
			width: 100%;
			height: 3px;
			background: $other_c4;
			@include radius(6px);
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			flex-basis: $single_interview_txt_w_md;
		}
		@include media-breakpoint-up(lg) {
			flex-basis: $single_interview_txt_w_lg;
		}
	}
	&__img {
		@include media-breakpoint-up(md) {
			flex-basis: $single_interview_img_w_md;
		}
		@include media-breakpoint-up(lg) {
			flex-basis: $single_interview_img_w_lg;
		}
		&__inner {
			@include aspect-img;
			@include radius;
			overflow: hidden;
		}
		figcaption {
			margin-top: 0.5rem;
			line-height: 1.4;
			z-index: 1;
			padding-left: 1.5rem;
			position: relative;
			&::before {
				content: "●";
				position: absolute;
				top: 0;
				left: 0;
				color: $point_c;
			}
		}
	}
}

// single-cont__video
.single-cont__video {
	padding: clamp(1.5em, 3vw, 3em) clamp(1.5em, 5%, 4em) clamp(2em, 3vw, 3em);
	background: $other_c2;
	@include radius_m;
	margin-bottom: clamp(3rem, 7vw, 7rem);
	&__tit {
		position: relative;
		@include f-w(700);
		@include line-h(1.4);
		@include f-s_xxs(1.14, 12);
		text-align: center;
		margin-bottom: clamp(1.25em, 2vw, 1.75rem);
		padding-bottom: clamp(0.75em, 1vw, 1rem);
		@include media-breakpoint-up(xl) {
			@include f-size(28);
		}
		&::after {
			position: absolute;
			bottom: 0;
			left: calc(50% - 1em);
			content: "";
			width: 2em;
			height: 0.2em;
			@include radius(10px);
			background: $sub_c;
		}
	}
	&__inner {
		@include media-breakpoint-up(lg) {
			display: grid;
			grid-template-columns: 1fr 40%;
			grid-template-rows: auto 1fr;
			column-gap: 7%;
			row-gap: clamp(1rem, 1.5vw, 2rem);
		}
	}
	&__video {
		@include media-breakpoint-up(lg) {
			grid-column: 2 / 3;
			grid-row: 1 / 2;
		}
		@include media-breakpoint-down(md) {
			@include m-a;
			max-width: 480px;
			margin-bottom: 1em;
		}
		&__inner {
			position: relative;
			width: 100%;
			height: 0;
			@include radius;
			overflow: hidden;
			padding-bottom: 56.25%;
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
		}
	}
}

.single-cont__foot {
	&__date {
		padding-top: 1em;
		padding-bottom: 1em;
		text-align: right;
		@include f-w(500);
		color: $dl-gray;
	}
}
