@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	l-sketch
/--------------------------------------------------------------------*/
// l-sketch-about
.l-sketch-about {
	position: relative;
	padding-bottom: clamp(3em, 6vw, 6em);
	&::before {
		position: absolute;
		left: 0;
		bottom: 0;
		content: "";
		width: 100%;
		height: 60%;
		background: $other_c8;
	}
	&__inner {
	}
	&__tit {
		position: relative;
		bottom: -2em;
		z-index: z-index(module, part03);
		&__add {
			@include m-a;
			width: clamp(90px, 35%, 210px);
		}
		&__inner {
			text-align: center;
			@include line-h(1);
			@include f-s_xxs(1.14, 12);
			@include media-breakpoint-up(xl) {
				@include f-size(30);
			}
			span {
				display: inline-block;
				padding: 0.75em 1.5em;
				@include f-w(700);
				color: $white;
				background: $main_c;
				@include radius;
			}
		}
	}
	&__txt {
		position: relative;
		padding: clamp(3em, 4vw, 4em) clamp(1.5em, 5%, 4.5em) clamp(7em, 12vw, 12em);
		@include radius_m;
		border: 1px solid $l-gray;
		background: $white;
		z-index: z-index(module, part02);
		@include media-breakpoint-up(md) {
			padding-bottom: clamp(10em, 17vw, 14em);
		}
		&__add {
			position: relative;
			top: -1em;
		}
		&__inner {
			@include m-a;
			max-width: 1100px;
		}
		&__img {
			position: absolute;
			bottom: -1em;
			left: 50%;
			transform: translateX(-50%);
			width: clamp(140px, 50%, 624px);
		}
		&__tit {
			max-width: 876px;
			@include m-a;
			margin-bottom: clamp(1.5em, 3vw, 3em);
		}
		&__txt {
			max-width: 876px;
			@include m-a;
			margin-bottom: clamp(2em, 3vw, 3em);
		}
		&__box {
			color: $sub_c;
			@include f-w(700);
			background: $bg_c1;
			padding: clamp(1.5em, 2vw, 2em) clamp(1.5em, 5%, 4.5em) clamp(1.5em, 2vw, 2em);
			@include radius_s;
			@include f-s_xxs(1, 6);
			@include media-breakpoint-up(lg) {
				text-align: center;
			}
			@include media-breakpoint-up(xl) {
				@include f-size(22);
			}
		}
	}
}

// l-sketch-video
.l-sketch-video {
	position: relative;
	padding-top: clamp(3em, 6vw, 6em);
	padding-bottom: clamp(6em, 9vw, 9em);
	&__catch {
		width: 90%;
		max-width: 1100px;
		@include m-a;
		position: relative;
		z-index: z-index(module, part02);
		&__inner {
			position: relative;
			padding: clamp(1em, 2.5vw, 2.5em) 5%;
			@include f-s_xxs(1, 12);
			@include f-w(700);
			color: $white;
			background: $hover_c;
			@include radius_m;
			@include media-breakpoint-up(md) {
				text-align: center;
			}
			@include media-breakpoint-up(xl) {
				@include f-size(32);
			}
			&::after {
				position: absolute;
				top: calc(100% - 1px);
				left: calc(50% - 1em);
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 1.5em 1em 0 1em;
				border-color: $hover_c transparent transparent transparent;
			}
		}
	}
	&__txt {
		position: relative;
		width: 100%;
		padding: clamp(6em, 9vw, 9em) 5% clamp(1.5em, 3vw, 3em);
		background: $white;
		margin-top: -4em;
		@include radius_m;
		box-shadow: 0 0 10px rgba($black, 0.1);
		&__video {
			max-width: 960px;
			@include m-a;
			iframe {
				width: 100%;
				height: 100%;
				aspect-ratio: 16 / 9;
			}
		}
		&__tit {
			margin-top: clamp(1em, 2vw, 2em);
			text-align: center;
			span {
				display: inline-block;
				background: $other_c4;
				padding: 0.5em 1.25em;
				@include radius;
				@include f-s_xxs(0.94, 6);
				@include f-w(700);
				@include media-breakpoint-up(xl) {
					@include f-size(24);
				}
			}
		}
	}
}

// l-sketch-voice
.l-sketch-voice {
	padding-top: clamp(3em, 6vw, 6em);
	padding-bottom: clamp(3em, 6vw, 6em);
	&__inner {
		display: flex;
		@include media-breakpoint-up(md) {
			column-gap: 5.48%;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: clamp(2em, 3vw, 3em);
		}
	}
	&__img {
		@include media-breakpoint-up(md) {
			width: 46.52%;
		}
		@include media-breakpoint-down(md) {
			max-width: 480px;
			@include m-a;
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			width: 48%;
		}
		
		@include media-breakpoint-down(sm) {
			max-inline-size: max-content;
			margin-inline: auto;
		}
		&__add {
			width: clamp(240px, 60%, 328px);
		}
		&__txt {
			@include f-w(700);
			@include line-h(1.5);
			@include f-s_xxs(1.14, 16);
			@include media-breakpoint-up(md) {
				@include f-s_md(1.25, 24);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(45);
			}
		}
		&__btn {
			width: clamp(240px, 100%, 360px);
			margin-top: clamp(2em, 3vw, 3em);
			@include media-breakpoint-down(sm) {
				@include m-a;
			}
			a {
				font-size: 1em;
				padding: 0.75em 3%;
				@include media-breakpoint-up(lg) {
					font-size: 1.25em;
				}
			}
		}
	}
}

// l-sketch-reason
.l-sketch-reason {
	padding-bottom: clamp(3em, 9vw, 9em);
	background: $bg_c10;
	&__inner {
		padding-top: clamp(2.5em, 5vw, 5em);
	}
	&__tit {
		position: relative;
		text-align: center;
		@include line-h(1);
		margin-bottom: clamp(2em, 3vw, 3em);
		span {
			color: $txt_c;
			@include f-w(700);
			display: block;
			@include f-s_xxs(1, 10);
			@include media-breakpoint-up(xl) {
				@include f-size(28);
			}
		}
		strong {
			color: $main_c;
			display: block;
			@include f-w(700);
			@include f-s_xxs(2, 24);
			@include media-breakpoint-up(xl) {
				@include f-size(56);
			}
			b {
				font-size: 1.5em;
			}
		}
		&::before {
			position: absolute;
			bottom: calc(100% + 3vw);
			left: 50%;
			transform: translateX(-50%);
			content: "";
			width: clamp(63px, 10vw, 116px);
			height: clamp(37px, 5.86vw, 68px);
			background: url(../img/lv2/decoration_tit.png) center center/contain no-repeat;
			@include media-breakpoint-up(xl) {
				bottom: calc(100% + 3em);
				width: 116px;
				height: 68px;
			}
		}
	}
	&__list {
		counter-reset: reason-num;
		@include flex-column;
		row-gap: clamp(2em, 3vw, 3em);
	}
}

.l-sketch-reason__box {
	padding: clamp(1.5em, 3vw, 3em) clamp(1.5em, 5%, 3em) clamp(2em, 4vw, 4em);
	@include radius_s;
	background: $white;
	box-shadow: 0 0 10px rgba($black, 0.1);
	&__inner {
		display: flex;
		@include media-breakpoint-up(md) {
			flex-direction: row-reverse;
			column-gap: 5%;
			width: 100%;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: 1.5em;
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			width: 60.17%;
		}
		&__tit {
			display: flex;
			column-gap: 0.75em;
			@include f-s_xxs(1.14, 16);
			margin-bottom: clamp(1.5em, 2vw, 2em);
			@include media-breakpoint-up(md) {
				@include f-s_md(1.25, 10);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(30);
			}
			& > span {
				width: 12vw;
				text-align: center;
				flex-shrink: 0;
				@include media-breakpoint-up(md) {
					width: 6.25vw;
				}
				@include media-breakpoint-up(xl) {
					width: 90px;
				}
				span {
					position: relative;
					display: block;
					width: 100%;
					height: 12vw;
					background: $main_c;
					color: $white;
					@include l-sp(0);
					@include radius(50%);
					@include media-breakpoint-up(md) {
						width: 100%;
						height: 6.25vw;
					}
					@include media-breakpoint-up(xl) {
						width: 100%;
						height: 90px;
					}
					&::before {
						position: absolute;
						counter-increment: reason-num;
						top: 0.25em;
						right: 0;
						bottom: 0;
						left: 0;
						margin: auto;
						@include line-h(1);
						@include f-s_xxs(1.5, 74);
						content: counter(reason-num, decimal-leading-zero);
						@include media-breakpoint-up(md) {
							@include f-s_md(2, 26);
						}
						@include media-breakpoint-up(xl) {
							@include f-size(58);
						}
					}
				}
			}
			& > b {
				padding-top: 0.75em;
				@include f-w(700);
			}
		}
	}
	&__img {
		@include media-breakpoint-up(md) {
			width: 35.83%;
		}
		@include media-breakpoint-down(sm) {
			max-width: 500px;
			@include m-a;
		}
	}
}

/* l-sketch-service */
.l-sketch-service {
	background: $bg_c1;
	&__head {
		&__tit {
			@include m-a;
			width: clamp(200px, 90%, 1100px);
		}
	}
	&__txt {
		position: relative;
		background: rgba($sub_c, 0.7);
		margin-top: clamp(3em, 6vw, 6em);
		padding-bottom: clamp(3em, 8vw, 8em);
		&::before {
			position: absolute;
			left: 0;
			bottom: 0;
			content: "";
			width: 100%;
			height: 75%;
			background: $sub_c;
			@include media-breakpoint-up(xl) {
				height: calc(100% - 20em);
			}
		}
		&__inner {
			position: relative;
			padding-top: clamp(4.75em, 7vw, 8em);
			z-index: z-index(module, part02);
		}
		&__tit {
			position: absolute;
			top: -1.5em;
			left: 0;
			right: 0;
			text-align: center;
			@include f-s_xxs(0.94, 12);
			@include media-breakpoint-up(xl) {
				@include f-size(32);
			}
			span {
				display: inline-block;
				padding: 0.75em 1em;
				@include f-w(700);
				background: $white;
				@include radius;
				@include media-breakpoint-up(md) {
					padding: 0.75em 1.75em;
				}
			}
		}
		&__list {
			@include m-a;
			max-width: 1240px;
			display: grid;
			row-gap: 2.5em;
			counter-reset: plan-num;
			@include media-breakpoint-up(md) {
				grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
				column-gap: 2%;
			}
			@include media-breakpoint-up(lg) {
				grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
				column-gap: 2.8%;
			}
		}
		&__txt {
			margin-top: clamp(2em, 3vw, 3em);
			color: $white;
			@include f-s_xxs(0.94, 8);
			@include media-breakpoint-up(lg) {
				text-align: center;
			}
			@include media-breakpoint-up(xl) {
				@include f-size(24);
			}
		}
		&__btn {
			min-width: 240px;
			margin-top: clamp(3em, 4vw, 4em);
			max-width: 360px;
			@include m-a;
			a {
				font-size: 1em;
				padding: 0.75em 3%;
			}
		}
	}
}

.l-sketch-service__box {
	position: relative;
	padding: clamp(1.5em, 3vw, 3em) clamp(1.5em, 5%, 2em) clamp(1.5em, 3vw, 3em);
	content: "";
	background: $white;
	@include radius_l(8px, 6vw, 96px);
	@include media-breakpoint-up(md) {
		padding: clamp(1.5em, 3vw, 3em) clamp(1em, 5%, 2em) clamp(1.25em, 3vw, 3em);
	}
	@include media-breakpoint-up(lg) {
		padding: clamp(1.5em, 3vw, 3em) clamp(2em, 8%, 4.5em) clamp(1.5em, 3vw, 3em);
	}
	&::before {
		position: absolute;
		bottom: calc(100% - 0.5em);
		left: 50%;
		transform: translateX(-50%);
		color: $other_c4;
		@include f-family(font02);
		@include l-sp(0);
		@include line-h(1);
		counter-increment: plan-num;
		content: counter(plan-num, decimal-leading-zero);
		@include f-w(500);
		@include f-s_xxs(3, 48);
		@include media-breakpoint-up(md) {
			@include f-s_md(4, 16);
		}
		@include media-breakpoint-up(lg) {
			font-size: 5em;
		}
	}
	&__inner {
		@include flex-c-reverse;
		row-gap: 0.75em;
	}
	&__txt {
		text-align: center;
		@include f-w(700);
		@include line-h(1.5);
		@include f-s_xxs(1, 8);
		@include media-breakpoint-up(md) {
			@include f-s_md(0.8, 6);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(22);
		}
	}
	&__img {
		width: 90%;
		@include m-a;
		max-width: 320px;
		&__inner {
			@include aspect-img(57, 37);
		}
	}
}

/* l-sketch-reserve */
.l-sketch-reserve {
	padding-top: clamp(2em, 4vw, 4em);
	padding-bottom: clamp(3em, 6vw, 6em);
	background: $bg_c1;
	@include media-breakpoint-down(sm) {
		padding-bottom: clamp(7em, 12vw, 12em);
	}
	.l-cont {
		position: relative;
	}
	&__add {
		position: absolute;

		@include media-breakpoint-up(md) {
			top: 0;
			width: 21.8%;
		}
		@include media-breakpoint-down(sm) {
			bottom: clamp(-7em, -12vw, -12em);
			width: 20vw;
		}
		&.is-add01 {
			left: 2%;
		}
		&.is-add02 {
			right: 2%;
		}
	}
	&__inner {
		@include media-breakpoint-up(md) {
			@include m-a;
			width: 56%;
		}
	}
	&__tit {
		width: 94%;
		@include m-a;
		margin-bottom: clamp(0.75em, 1.5vw, 1.5em);
	}
	&__txt {
		margin-bottom: 0.75em;
		text-align: center;
		@include media-breakpoint-up(md) {
			@include f-s_md(1, 4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(24);
		}
	}
	&__btn {
		@include m-a;
		max-width: 360px;
		@include media-breakpoint-up(md) {
			max-width: 700px;
		}
		a {
			@include btn_arrow;
			@include media-breakpoint-up(md) {
				@include f-s_md(1.25, 10);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(32);
			}
		}
	}
}
