@charset "UTF-8";
/* webfont 変数　*/
/* ofi */
@media (min-width: 1753px) {
  /*====================================================================/
  /*--Large PC 以上--
  /====================================================================*/
}
@media (min-width: 1553px) {
  /*====================================================================/
  /*---PC 以上--
  /====================================================================*/
}
@media (min-width: 1176px) {
  /*====================================================================/
  /*--XGAサイズ　iPadPro 以上--
  /====================================================================*/
}
@media (min-width: 768px) {
  /*====================================================================/
  /*--タブレット　iPadmini 以上--
  /====================================================================*/
}
@media (min-width: 576px) {
  /*====================================================================/
  /*--スマホ 以上--
  /====================================================================*/
}
@media (min-width: 376px) {
  /*====================================================================/
  /*--iPhone 以上--
  /====================================================================*/
}
@media (max-width: 1752px) {
  /*====================================================================/
  /*--Large PC 未満--
  /====================================================================*/
}
@media (max-width: 1552px) {
  /*====================================================================/
  /*--PC 未満--
  /====================================================================*/
}
@media (max-width: 1175px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro 未満--
  /====================================================================*/
}
@media (max-width: 767px) {
  /*====================================================================/
  /*--タブレット iPadmini 未満--
  /====================================================================*/
}
@media (max-width: 575px) {
  /*====================================================================/
  /*--iPhone 以下--
  /====================================================================*/
}
@media (min-width: 576px) and (max-width: 767px) {
  /*====================================================================/
  /*--スマホ以上 タブレット iPadmini未満--
  /====================================================================*/
}
@media (min-width: 768px) and (max-width: 1175px) {
  /*====================================================================/
  /*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
  /====================================================================*/
}
@media (min-width: 1176px) and (max-width: 1552px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro以上 PC未満--
  /====================================================================*/
}
@media (min-width: 1553px) and (max-width: 1752px) {
  /*====================================================================/
  /*--px以上 Large PC未満--
  /====================================================================*/
}
/*====================================================================

lv2.css

=====================================================================*/
/*--------------------------------------------------------------------/
	breadcrumb
/--------------------------------------------------------------------*/
.breadcrumb {
  padding: 0 0 clamp(2em, 3vw, 3em);
}
.breadcrumb__list {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.94em;
  row-gap: 0.3em;
}
.breadcrumb__list .item-home {
  font-size: 1.2em;
}
.breadcrumb__list li {
  position: relative;
  line-height: 1.2;
}
.breadcrumb__list li:not(:last-child) {
  padding-right: 1.5rem;
}
.breadcrumb__list li:not(:last-child):after {
  content: "\e315";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.breadcrumb__list li:not(:last-child)::after {
  position: absolute;
  right: 0.3rem;
  top: 50%;
  transform: translateY(-50%);
}
.breadcrumb__list a {
  color: #979DA4;
  transition: all 0.3s ease 0s;
  vertical-align: top;
}
.breadcrumb__list a:first-child {
  text-decoration: none !important;
}
.breadcrumb__list a:hover {
  color: #12A40A;
}

/*--------------------------------------------------------------------/
	about
/--------------------------------------------------------------------*/
.about-intro {
  padding-top: clamp(2em, 3vw, 3em);
  padding-bottom: clamp(7em, 20vw, 20em);
  background: url(../img/common/bg_line.png) 0 0 repeat;
}
.about-intro__inner {
  width: 100%;
  background: #fff;
  padding: clamp(2em, 3vw, 3em) clamp(1.5em, 5%, 5em) clamp(2.5em, 4vw, 4em);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: clamp(8px, 2.5vw, 40px);
}
@media (min-width: 768px) {
  .about-intro__inner {
    padding: clamp(2em, 4.5vw, 5em) clamp(1.5em, 5%, 5em) clamp(2.5em, 5vw, 6em);
  }
}
.about-intro__flex {
  display: flex;
}
@media (min-width: 768px) {
  .about-intro__flex {
    justify-content: space-between;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .about-intro__flex {
    flex-direction: column;
    row-gap: 0.5em;
  }
}
@media (min-width: 768px) {
  .about-intro__txt {
    width: 54%;
  }
}
.about-intro__txt__tit {
  color: #4CAE47;
}
@media (min-width: 1553px) {
  .about-intro__txt__txt {
    font-size: 1.43rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .about-intro__txt__txt {
    font-size: 1.33rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .about-intro__txt__txt {
    font-size: 1.25rem;
  }
}
@media (min-width: 768px) {
  .about-intro__img {
    width: 32.43%;
  }
}
@media (max-width: 767px) {
  .about-intro__img {
    display: flex;
    justify-content: flex-end;
  }
  .about-intro__img > div {
    width: 40%;
  }
}

.about-video {
  position: relative;
  margin-top: -10vw;
}
@media (min-width: 1553px) {
  .about-video {
    margin-top: -12rem;
  }
}
.about-video::before {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 20%;
  background: #F8F2E0 url(../img/common/bg_orange.png) 0 0 repeat;
}
.about-video__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 1300px;
}
.about-video__catch {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
}
.about-video__catch__inner {
  position: relative;
  padding: clamp(1em, 2.5vw, 2.5em) 5%;
  font-size: calc(1rem + 12 * (100vw - 280px) / 1160);
  font-weight: 700;
  color: #fff;
  background: #54BA00;
  border-radius: clamp(6px, 2vw, 30px);
}
@media (min-width: 768px) {
  .about-video__catch__inner {
    text-align: center;
  }
}
@media (min-width: 1553px) {
  .about-video__catch__inner {
    font-size: 2.29rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .about-video__catch__inner {
    font-size: 2.13rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .about-video__catch__inner {
    font-size: 2rem;
  }
}
.about-video__catch__inner::after {
  position: absolute;
  top: calc(100% - 1px);
  left: calc(50% - 1em);
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 1.5em 1em 0 1em;
  border-color: #54BA00 transparent transparent transparent;
}
.about-video__txt {
  position: relative;
  width: 100%;
  padding: clamp(6em, 9vw, 9em) 5% clamp(1.5em, 3vw, 3em);
  background: #fff;
  margin-top: -4em;
  border-radius: clamp(6px, 2vw, 30px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.about-video__txt__video {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}
.about-video__txt__video iframe {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
}
.about-video__txt__tit {
  margin-top: 1em;
  text-align: center;
}
.about-video__txt__tit span {
  display: inline-block;
  background: #FDD035;
  padding: 0.5em 1.25em;
  border-radius: 5px;
  font-size: calc(0.94rem + 6 * (100vw - 280px) / 1160);
  font-weight: 700;
}
@media (min-width: 1553px) {
  .about-video__txt__tit span {
    font-size: 1.71rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .about-video__txt__tit span {
    font-size: 1.6rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .about-video__txt__tit span {
    font-size: 1.5rem;
  }
}

.about-list {
  padding-top: clamp(6em, 9vw, 9em);
  margin-bottom: 1em;
  background: #F8F2E0 url(../img/common/bg_orange.png) 0 0 repeat;
  display: flex;
  flex-direction: column;
  row-gap: clamp(2em, 4vw, 4em);
}

.about-box {
  position: relative;
}
@media (min-width: 768px) {
  .about-box::before {
    position: absolute;
    top: 0;
    content: "";
    width: 90%;
    height: 100%;
    background: #fff;
  }
}
@media (min-width: 1553px) {
  .about-box::before {
    width: calc(50% + 720px);
  }
}
@media (min-width: 768px) {
  .about-box:nth-of-type(odd)::before {
    right: 0;
    border-top-left-radius: clamp(8px, 2.5vw, 40px);
    border-bottom-left-radius: clamp(8px, 2.5vw, 40px);
  }
}
@media (min-width: 768px) {
  .about-box:nth-of-type(even)::before {
    left: 0;
    border-top-right-radius: clamp(8px, 2.5vw, 40px);
    border-bottom-right-radius: clamp(8px, 2.5vw, 40px);
  }
}
@media (min-width: 768px) {
  .about-box:nth-of-type(even) .l-cont_xxl {
    display: flex;
    justify-content: flex-end;
  }
}
@media (min-width: 768px) {
  .about-box:nth-of-type(even) .about-box__inner {
    flex-direction: row-reverse;
  }
}

.about-box__inner {
  position: relative;
  display: flex;
  max-width: 1540px;
  width: 100%;
  z-index: 1;
}
@media (min-width: 768px) {
  .about-box__inner {
    padding-top: clamp(3em, 6vw, 6em);
    padding-bottom: clamp(3em, 6vw, 6em);
    column-gap: 5%;
  }
}
@media (max-width: 767px) {
  .about-box__inner {
    flex-direction: column;
    row-gap: 1em;
    padding: 0 5% clamp(1.5em, 2.5vw, 2.5em);
  }
}
@media (max-width: 767px) {
  .about-box__inner::before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    width: 100%;
    height: calc(100% - 25vw);
    background: #fff;
    border-radius: clamp(6px, 2vw, 30px);
    z-index: -1;
  }
}
@media (min-width: 768px) {
  .about-box__txt {
    width: 65%;
  }
}
@media (min-width: 1553px) {
  .about-box__txt {
    width: 52%;
  }
}
.about-box__txt__tit {
  color: #4CAE47;
  font-size: calc(1.14rem + 14 * (100vw - 280px) / 1160);
  margin-bottom: clamp(0.75em, 1.5vw, 1.25em);
}
@media (min-width: 768px) {
  .about-box__txt__tit {
    font-size: calc(1.37rem + 10 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1553px) {
  .about-box__txt__tit {
    font-size: 2.29rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .about-box__txt__tit {
    font-size: 2.13rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .about-box__txt__tit {
    font-size: 2rem;
  }
}
@media (min-width: 1553px) {
  .about-box__txt__txt {
    font-size: 1.29rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .about-box__txt__txt {
    font-size: 1.2rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .about-box__txt__txt {
    font-size: 1.13rem;
  }
}
.about-box__txt__txt .txt ul {
  margin-bottom: 1em;
}
.about-box__txt__txt .txt ul li {
  position: relative;
  padding-left: 1em;
  position: relative;
}
.about-box__txt__txt .txt ul li::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "・";
  font-size: 1em;
}
@media (min-width: 768px) {
  .about-box__img {
    margin-top: 2em;
    width: 30%;
  }
}
@media (min-width: 1553px) {
  .about-box__img {
    width: 43%;
  }
}
@media (max-width: 767px) {
  .about-box__img {
    width: 86%;
    margin-left: auto;
    margin-right: auto;
  }
}
.about-box__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
}
.about-box__img__inner::before {
  content: "";
  display: block;
  padding-top: 76.47%;
  position: relative;
}
.about-box__img__inner > div,
.about-box__img__inner figure,
.about-box__img__inner a,
.about-box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/*--------------------------------------------------------------------/
	service
/--------------------------------------------------------------------*/
.service-intro__inner {
  display: flex;
  flex-direction: column;
  row-gap: clamp(2em, 3vw, 3em);
}
.service-intro__txt {
  font-size: calc(0.94rem + 6 * (100vw - 280px) / 1160);
}
@media (min-width: 768px) {
  .service-intro__txt {
    text-align: center;
  }
}
@media (min-width: 1553px) {
  .service-intro__txt {
    font-size: 1.57rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .service-intro__txt {
    font-size: 1.47rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .service-intro__txt {
    font-size: 1.38rem;
  }
}

.service-intro__detail {
  padding: clamp(1.5em, 3vw, 3em) clamp(1.5em, 5%, 5em) clamp(5em, 7vw, 7em);
  border-radius: clamp(6px, 2vw, 30px);
  background: #F6F6F8 url(../img/common/bg_gray.png) 0 0 repeat;
}
@media (min-width: 768px) {
  .service-intro__detail {
    padding: clamp(2em, 3vw, 3em) clamp(1.5em, 8%, 6em) clamp(2em, 4vw, 4em);
  }
}
@media (max-width: 767px) {
  .service-intro__detail {
    margin-bottom: clamp(1em, 3vw, 6em);
  }
}
@media (min-width: 768px) {
  .service-intro__detail__grid {
    display: grid;
    grid-template-columns: 1fr 30%;
    grid-template-rows: auto 1fr;
    gap: 0 4%;
  }
}
@media (min-width: 1176px) {
  .service-intro__detail__grid {
    grid-template-columns: 1fr 20.83%;
    gap: 0 7.7%;
  }
}
@media (max-width: 1175px) {
  .service-intro__detail__grid {
    position: relative;
  }
}
.service-intro__detail__tit {
  position: relative;
  padding: 0.5em 1em;
  margin-bottom: clamp(1em, 1.5vw, 1.5em);
  border-radius: 5px;
  color: #fff;
  background: #4CAE47;
  text-align: center;
  font-weight: 700;
  font-size: calc(1rem + 10 * (100vw - 280px) / 1160);
}
@media (min-width: 768px) {
  .service-intro__detail__tit {
    margin-top: 1em;
    font-size: calc(1.5rem + 10 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1553px) {
  .service-intro__detail__tit {
    font-size: 2.57rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .service-intro__detail__tit {
    font-size: 2.4rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .service-intro__detail__tit {
    font-size: 2.25rem;
  }
}
.service-intro__detail__tit b {
  font-weight: 700;
  color: #FDD035;
  padding: 0 0.3em;
}
@media (min-width: 768px) {
  .service-intro__detail__tit {
    grid-column: 1/2;
    grid-row: 1/2;
  }
}
@media (min-width: 768px) {
  .service-intro__detail__tit::after {
    position: absolute;
    left: calc(100% - 1px);
    top: calc(50% - 0.35em);
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.35em 0 0.35em 0.6em;
    border-color: transparent transparent transparent #4CAE47;
  }
}
@media (min-width: 768px) {
  .service-intro__detail__txt {
    grid-column: 1/2;
    grid-row: 2/3;
    line-height: 2;
  }
}
@media (min-width: 1553px) {
  .service-intro__detail__txt {
    font-size: 1.43rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .service-intro__detail__txt {
    font-size: 1.33rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .service-intro__detail__txt {
    font-size: 1.25rem;
  }
}
@media (min-width: 768px) {
  .service-intro__detail__img {
    grid-column: 2/3;
    grid-row: 1/3;
  }
}
@media (max-width: 767px) {
  .service-intro__detail__img {
    position: absolute;
    top: calc(100% - 2vw);
    right: -5%;
    width: clamp(90px, 20%, 200px);
  }
}

.service-list {
  padding-top: clamp(6em, 9vw, 9em);
  display: flex;
  flex-direction: column;
  row-gap: clamp(5em, 9vw, 9em);
}

.service-box {
  position: relative;
  padding-bottom: clamp(3em, 4vw, 4em);
}
.service-box::before {
  position: absolute;
  bottom: 0;
  content: "";
  width: 100%;
  height: 80%;
}
.service-box:nth-of-type(odd)::before {
  background: #54BA00;
  right: 0;
}
@media (min-width: 768px) {
  .service-box:nth-of-type(odd)::before {
    width: 97.5%;
    border-top-left-radius: clamp(8px, 1.5vw, 30px);
    border-bottom-left-radius: clamp(8px, 1.5vw, 30px);
  }
}
@media (min-width: 1921px) {
  .service-box:nth-of-type(odd)::before {
    width: calc(50% + 860px);
  }
}
@media (min-width: 768px) {
  .service-box:nth-of-type(odd) .service-box__inner {
    padding: clamp(2em, 3vw, 3em) 0 clamp(2em, 4vw, 4em) clamp(1.5em, 8%, 6em);
  }
  .service-box:nth-of-type(odd) .service-box__inner::before {
    left: 0;
  }
}
.service-box:nth-of-type(odd) .service-box__txt__tit {
  border-color: #4CAE47;
}
.service-box:nth-of-type(even)::before {
  left: 0;
  background: #FDD035;
}
@media (min-width: 768px) {
  .service-box:nth-of-type(even)::before {
    width: 97.5%;
    border-top-right-radius: clamp(8px, 1.5vw, 30px);
    border-bottom-right-radius: clamp(8px, 1.5vw, 30px);
  }
}
@media (min-width: 1921px) {
  .service-box:nth-of-type(even)::before {
    width: calc(50% + 860px);
  }
}
@media (min-width: 768px) {
  .service-box:nth-of-type(even) .service-box__inner {
    flex-direction: row-reverse;
    padding: clamp(2em, 3vw, 3em) clamp(2em, 4vw, 4em) clamp(1.5em, 8%, 6em) 0;
  }
  .service-box:nth-of-type(even) .service-box__inner::before {
    right: 0;
  }
}
.service-box:nth-of-type(even) .service-box__txt__tit {
  border-color: #E86607;
}

.service-box__inner {
  position: relative;
  display: flex;
  width: 100%;
  padding: clamp(1.5em, 3vw, 3em) clamp(1.5em, 5%, 5em) clamp(2em, 4vw, 4em);
  z-index: 2;
}
@media (min-width: 768px) {
  .service-box__inner {
    column-gap: 4%;
    padding: clamp(2em, 3vw, 3em) clamp(1.5em, 8%, 6em) clamp(2em, 4vw, 4em);
  }
}
@media (min-width: 1553px) {
  .service-box__inner {
    column-gap: 8.89%;
  }
}
@media (max-width: 767px) {
  .service-box__inner {
    flex-direction: column-reverse;
    row-gap: clamp(1.5rem, 2.5vw, 2rem);
    background: #fff;
    border-radius: clamp(6px, 2vw, 30px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}
@media (min-width: 768px) {
  .service-box__inner::before {
    position: absolute;
    top: 0;
    content: "";
    width: 80%;
    height: 100%;
    background: #fff;
    border-radius: clamp(6px, 2vw, 30px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: -1;
  }
}
@media (min-width: 768px) {
  .service-box__txt {
    width: 54%;
  }
}
@media (min-width: 1553px) {
  .service-box__txt {
    width: 60%;
  }
}
.service-box__txt__tit {
  font-size: calc(1.14rem + 12 * (100vw - 280px) / 1160);
  font-weight: 700;
  padding-bottom: clamp(0.5em, 0.75vw, 0.75em);
  margin-bottom: clamp(0.75em, 1vw, 1em);
  border-bottom: 0.3em dotted #979DA4;
}
@media (min-width: 768px) {
  .service-box__txt__tit {
    font-size: calc(1.14rem + 10 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1553px) {
  .service-box__txt__tit {
    font-size: 2.29rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .service-box__txt__tit {
    font-size: 2.13rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .service-box__txt__tit {
    font-size: 2rem;
  }
}
@media (min-width: 1176px) {
  .service-box__txt__txt {
    line-height: 2;
  }
}
@media (min-width: 1553px) {
  .service-box__txt__txt {
    font-size: 1.29rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .service-box__txt__txt {
    font-size: 1.2rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .service-box__txt__txt {
    font-size: 1.13rem;
  }
}
@media (min-width: 768px) {
  .service-box__img {
    width: 42%;
  }
}
@media (min-width: 1553px) {
  .service-box__img {
    width: 31.11%;
  }
}
.service-box__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  border-radius: clamp(4px, 1.5vw, 16px);
  overflow: hidden;
}
.service-box__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.service-box__img__inner > div,
.service-box__img__inner figure,
.service-box__img__inner a,
.service-box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/*--------------------------------------------------------------------/
	l-sketch
/--------------------------------------------------------------------*/
.l-sketch-about {
  position: relative;
  padding-bottom: clamp(3em, 6vw, 6em);
}
.l-sketch-about::before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 60%;
  background: #92D459;
}
.l-sketch-about__tit {
  position: relative;
  bottom: -2em;
  z-index: 3;
}
.l-sketch-about__tit__add {
  margin-left: auto;
  margin-right: auto;
  width: clamp(90px, 35%, 210px);
}
.l-sketch-about__tit__inner {
  text-align: center;
  line-height: 1;
  font-size: calc(1.14rem + 12 * (100vw - 280px) / 1160);
}
@media (min-width: 1553px) {
  .l-sketch-about__tit__inner {
    font-size: 2.14rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .l-sketch-about__tit__inner {
    font-size: 2rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .l-sketch-about__tit__inner {
    font-size: 1.88rem;
  }
}
.l-sketch-about__tit__inner span {
  display: inline-block;
  padding: 0.75em 1.5em;
  font-weight: 700;
  color: #fff;
  background: #54BA00;
  border-radius: 5px;
}
.l-sketch-about__txt {
  position: relative;
  padding: clamp(3em, 4vw, 4em) clamp(1.5em, 5%, 4.5em) clamp(7em, 12vw, 12em);
  border-radius: clamp(6px, 2vw, 30px);
  border: 1px solid #D9D9D9;
  background: #fff;
  z-index: 2;
}
@media (min-width: 768px) {
  .l-sketch-about__txt {
    padding-bottom: clamp(10em, 17vw, 14em);
  }
}
.l-sketch-about__txt__add {
  position: relative;
  top: -1em;
}
.l-sketch-about__txt__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px;
}
.l-sketch-about__txt__img {
  position: absolute;
  bottom: -1em;
  left: 50%;
  transform: translateX(-50%);
  width: clamp(140px, 50%, 624px);
}
.l-sketch-about__txt__tit {
  max-width: 876px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: clamp(1.5em, 3vw, 3em);
}
.l-sketch-about__txt__txt {
  max-width: 876px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: clamp(2em, 3vw, 3em);
}
.l-sketch-about__txt__box {
  color: #4CAE47;
  font-weight: 700;
  background: #FFF9E5;
  padding: clamp(1.5em, 2vw, 2em) clamp(1.5em, 5%, 4.5em) clamp(1.5em, 2vw, 2em);
  border-radius: clamp(4px, 1.5vw, 16px);
  font-size: calc(1rem + 6 * (100vw - 280px) / 1160);
}
@media (min-width: 1176px) {
  .l-sketch-about__txt__box {
    text-align: center;
  }
}
@media (min-width: 1553px) {
  .l-sketch-about__txt__box {
    font-size: 1.57rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .l-sketch-about__txt__box {
    font-size: 1.47rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .l-sketch-about__txt__box {
    font-size: 1.38rem;
  }
}

.l-sketch-video {
  position: relative;
  padding-top: clamp(3em, 6vw, 6em);
  padding-bottom: clamp(6em, 9vw, 9em);
}
.l-sketch-video__catch {
  width: 90%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
}
.l-sketch-video__catch__inner {
  position: relative;
  padding: clamp(1em, 2.5vw, 2.5em) 5%;
  font-size: calc(1rem + 12 * (100vw - 280px) / 1160);
  font-weight: 700;
  color: #fff;
  background: #12A40A;
  border-radius: clamp(6px, 2vw, 30px);
}
@media (min-width: 768px) {
  .l-sketch-video__catch__inner {
    text-align: center;
  }
}
@media (min-width: 1553px) {
  .l-sketch-video__catch__inner {
    font-size: 2.29rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .l-sketch-video__catch__inner {
    font-size: 2.13rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .l-sketch-video__catch__inner {
    font-size: 2rem;
  }
}
.l-sketch-video__catch__inner::after {
  position: absolute;
  top: calc(100% - 1px);
  left: calc(50% - 1em);
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 1.5em 1em 0 1em;
  border-color: #12A40A transparent transparent transparent;
}
.l-sketch-video__txt {
  position: relative;
  width: 100%;
  padding: clamp(6em, 9vw, 9em) 5% clamp(1.5em, 3vw, 3em);
  background: #fff;
  margin-top: -4em;
  border-radius: clamp(6px, 2vw, 30px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.l-sketch-video__txt__video {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}
.l-sketch-video__txt__video iframe {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
}
.l-sketch-video__txt__tit {
  margin-top: clamp(1em, 2vw, 2em);
  text-align: center;
}
.l-sketch-video__txt__tit span {
  display: inline-block;
  background: #FDD035;
  padding: 0.5em 1.25em;
  border-radius: 5px;
  font-size: calc(0.94rem + 6 * (100vw - 280px) / 1160);
  font-weight: 700;
}
@media (min-width: 1553px) {
  .l-sketch-video__txt__tit span {
    font-size: 1.71rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .l-sketch-video__txt__tit span {
    font-size: 1.6rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .l-sketch-video__txt__tit span {
    font-size: 1.5rem;
  }
}

.l-sketch-voice {
  padding-top: clamp(3em, 6vw, 6em);
  padding-bottom: clamp(3em, 6vw, 6em);
}
.l-sketch-voice__inner {
  display: flex;
}
@media (min-width: 768px) {
  .l-sketch-voice__inner {
    column-gap: 5.48%;
  }
}
@media (max-width: 767px) {
  .l-sketch-voice__inner {
    flex-direction: column;
    row-gap: clamp(2em, 3vw, 3em);
  }
}
@media (min-width: 768px) {
  .l-sketch-voice__img {
    width: 46.52%;
  }
}
@media (max-width: 1175px) {
  .l-sketch-voice__img {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 768px) {
  .l-sketch-voice__txt {
    width: 48%;
  }
}
@media (max-width: 767px) {
  .l-sketch-voice__txt {
    max-inline-size: max-content;
    margin-inline: auto;
  }
}
.l-sketch-voice__txt__add {
  width: clamp(240px, 60%, 328px);
}
.l-sketch-voice__txt__txt {
  font-weight: 700;
  line-height: 1.5;
  font-size: calc(1.14rem + 16 * (100vw - 280px) / 1160);
}
@media (min-width: 768px) {
  .l-sketch-voice__txt__txt {
    font-size: calc(1.25rem + 24 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1553px) {
  .l-sketch-voice__txt__txt {
    font-size: 3.21rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .l-sketch-voice__txt__txt {
    font-size: 3rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .l-sketch-voice__txt__txt {
    font-size: 2.81rem;
  }
}
.l-sketch-voice__txt__btn {
  width: clamp(240px, 100%, 360px);
  margin-top: clamp(2em, 3vw, 3em);
}
@media (max-width: 767px) {
  .l-sketch-voice__txt__btn {
    margin-left: auto;
    margin-right: auto;
  }
}
.l-sketch-voice__txt__btn a {
  font-size: 1em;
  padding: 0.75em 3%;
}
@media (min-width: 1176px) {
  .l-sketch-voice__txt__btn a {
    font-size: 1.25em;
  }
}

.l-sketch-reason {
  padding-bottom: clamp(3em, 9vw, 9em);
  background: #FDF0E6;
}
.l-sketch-reason__inner {
  padding-top: clamp(2.5em, 5vw, 5em);
}
.l-sketch-reason__tit {
  position: relative;
  text-align: center;
  line-height: 1;
  margin-bottom: clamp(2em, 3vw, 3em);
}
.l-sketch-reason__tit span {
  color: #202020;
  font-weight: 700;
  display: block;
  font-size: calc(1rem + 10 * (100vw - 280px) / 1160);
}
@media (min-width: 1553px) {
  .l-sketch-reason__tit span {
    font-size: 2rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .l-sketch-reason__tit span {
    font-size: 1.87rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .l-sketch-reason__tit span {
    font-size: 1.75rem;
  }
}
.l-sketch-reason__tit strong {
  color: #54BA00;
  display: block;
  font-weight: 700;
  font-size: calc(2rem + 24 * (100vw - 280px) / 1160);
}
@media (min-width: 1553px) {
  .l-sketch-reason__tit strong {
    font-size: 4rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .l-sketch-reason__tit strong {
    font-size: 3.73rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .l-sketch-reason__tit strong {
    font-size: 3.5rem;
  }
}
.l-sketch-reason__tit strong b {
  font-size: 1.5em;
}
.l-sketch-reason__tit::before {
  position: absolute;
  bottom: calc(100% + 3vw);
  left: 50%;
  transform: translateX(-50%);
  content: "";
  width: clamp(63px, 10vw, 116px);
  height: clamp(37px, 5.86vw, 68px);
  background: url(../img/lv2/decoration_tit.png) center center/contain no-repeat;
}
@media (min-width: 1553px) {
  .l-sketch-reason__tit::before {
    bottom: calc(100% + 3em);
    width: 116px;
    height: 68px;
  }
}
.l-sketch-reason__list {
  counter-reset: reason-num;
  display: flex;
  flex-direction: column;
  row-gap: clamp(2em, 3vw, 3em);
}

.l-sketch-reason__box {
  padding: clamp(1.5em, 3vw, 3em) clamp(1.5em, 5%, 3em) clamp(2em, 4vw, 4em);
  border-radius: clamp(4px, 1.5vw, 16px);
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.l-sketch-reason__box__inner {
  display: flex;
}
@media (min-width: 768px) {
  .l-sketch-reason__box__inner {
    flex-direction: row-reverse;
    column-gap: 5%;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .l-sketch-reason__box__inner {
    flex-direction: column;
    row-gap: 1.5em;
  }
}
@media (min-width: 768px) {
  .l-sketch-reason__box__txt {
    width: 60.17%;
  }
}
.l-sketch-reason__box__txt__tit {
  display: flex;
  column-gap: 0.75em;
  font-size: calc(1.14rem + 16 * (100vw - 280px) / 1160);
  margin-bottom: clamp(1.5em, 2vw, 2em);
}
@media (min-width: 768px) {
  .l-sketch-reason__box__txt__tit {
    font-size: calc(1.25rem + 10 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1553px) {
  .l-sketch-reason__box__txt__tit {
    font-size: 2.14rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .l-sketch-reason__box__txt__tit {
    font-size: 2rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .l-sketch-reason__box__txt__tit {
    font-size: 1.88rem;
  }
}
.l-sketch-reason__box__txt__tit > span {
  width: 12vw;
  text-align: center;
  flex-shrink: 0;
}
@media (min-width: 768px) {
  .l-sketch-reason__box__txt__tit > span {
    width: 6.25vw;
  }
}
@media (min-width: 1553px) {
  .l-sketch-reason__box__txt__tit > span {
    width: 90px;
  }
}
.l-sketch-reason__box__txt__tit > span span {
  position: relative;
  display: block;
  width: 100%;
  height: 12vw;
  background: #54BA00;
  color: #fff;
  letter-spacing: 0;
  border-radius: 50%;
}
@media (min-width: 768px) {
  .l-sketch-reason__box__txt__tit > span span {
    width: 100%;
    height: 6.25vw;
  }
}
@media (min-width: 1553px) {
  .l-sketch-reason__box__txt__tit > span span {
    width: 100%;
    height: 90px;
  }
}
.l-sketch-reason__box__txt__tit > span span::before {
  position: absolute;
  counter-increment: reason-num;
  top: 0.25em;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  line-height: 1;
  font-size: calc(1.5rem + 74 * (100vw - 280px) / 1160);
  content: counter(reason-num, decimal-leading-zero);
}
@media (min-width: 768px) {
  .l-sketch-reason__box__txt__tit > span span::before {
    font-size: calc(2rem + 26 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1553px) {
  .l-sketch-reason__box__txt__tit > span span::before {
    font-size: 4.14rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .l-sketch-reason__box__txt__tit > span span::before {
    font-size: 3.87rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .l-sketch-reason__box__txt__tit > span span::before {
    font-size: 3.63rem;
  }
}
.l-sketch-reason__box__txt__tit > b {
  padding-top: 0.75em;
  font-weight: 700;
}
@media (min-width: 768px) {
  .l-sketch-reason__box__img {
    width: 35.83%;
  }
}
@media (max-width: 767px) {
  .l-sketch-reason__box__img {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* l-sketch-service */
.l-sketch-service {
  background: #FFF9E5;
}
.l-sketch-service__head__tit {
  margin-left: auto;
  margin-right: auto;
  width: clamp(200px, 90%, 1100px);
}
.l-sketch-service__txt {
  position: relative;
  background: rgba(76, 174, 71, 0.7);
  margin-top: clamp(3em, 6vw, 6em);
  padding-bottom: clamp(3em, 8vw, 8em);
}
.l-sketch-service__txt::before {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 75%;
  background: #4CAE47;
}
@media (min-width: 1553px) {
  .l-sketch-service__txt::before {
    height: calc(100% - 20em);
  }
}
.l-sketch-service__txt__inner {
  position: relative;
  padding-top: clamp(4.75em, 7vw, 8em);
  z-index: 2;
}
.l-sketch-service__txt__tit {
  position: absolute;
  top: -1.5em;
  left: 0;
  right: 0;
  text-align: center;
  font-size: calc(0.94rem + 12 * (100vw - 280px) / 1160);
}
@media (min-width: 1553px) {
  .l-sketch-service__txt__tit {
    font-size: 2.29rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .l-sketch-service__txt__tit {
    font-size: 2.13rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .l-sketch-service__txt__tit {
    font-size: 2rem;
  }
}
.l-sketch-service__txt__tit span {
  display: inline-block;
  padding: 0.75em 1em;
  font-weight: 700;
  background: #fff;
  border-radius: 5px;
}
@media (min-width: 768px) {
  .l-sketch-service__txt__tit span {
    padding: 0.75em 1.75em;
  }
}
.l-sketch-service__txt__list {
  margin-left: auto;
  margin-right: auto;
  max-width: 1240px;
  display: grid;
  row-gap: 2.5em;
  counter-reset: plan-num;
}
@media (min-width: 768px) {
  .l-sketch-service__txt__list {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    column-gap: 2%;
  }
}
@media (min-width: 1176px) {
  .l-sketch-service__txt__list {
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    column-gap: 2.8%;
  }
}
.l-sketch-service__txt__txt {
  margin-top: clamp(2em, 3vw, 3em);
  color: #fff;
  font-size: calc(0.94rem + 8 * (100vw - 280px) / 1160);
}
@media (min-width: 1176px) {
  .l-sketch-service__txt__txt {
    text-align: center;
  }
}
@media (min-width: 1553px) {
  .l-sketch-service__txt__txt {
    font-size: 1.71rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .l-sketch-service__txt__txt {
    font-size: 1.6rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .l-sketch-service__txt__txt {
    font-size: 1.5rem;
  }
}
.l-sketch-service__txt__btn {
  min-width: 240px;
  margin-top: clamp(3em, 4vw, 4em);
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
}
.l-sketch-service__txt__btn a {
  font-size: 1em;
  padding: 0.75em 3%;
}

.l-sketch-service__box {
  position: relative;
  padding: clamp(1.5em, 3vw, 3em) clamp(1.5em, 5%, 2em) clamp(1.5em, 3vw, 3em);
  content: "";
  background: #fff;
  border-radius: clamp(8px, 6vw, 96px);
}
@media (min-width: 768px) {
  .l-sketch-service__box {
    padding: clamp(1.5em, 3vw, 3em) clamp(1em, 5%, 2em) clamp(1.25em, 3vw, 3em);
  }
}
@media (min-width: 1176px) {
  .l-sketch-service__box {
    padding: clamp(1.5em, 3vw, 3em) clamp(2em, 8%, 4.5em) clamp(1.5em, 3vw, 3em);
  }
}
.l-sketch-service__box::before {
  position: absolute;
  bottom: calc(100% - 0.5em);
  left: 50%;
  transform: translateX(-50%);
  color: #FDD035;
  font-family: 'Rubik', sans-serif;
  letter-spacing: 0;
  line-height: 1;
  counter-increment: plan-num;
  content: counter(plan-num, decimal-leading-zero);
  font-weight: 500;
  font-size: calc(3rem + 48 * (100vw - 280px) / 1160);
}
@media (min-width: 768px) {
  .l-sketch-service__box::before {
    font-size: calc(4rem + 16 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1176px) {
  .l-sketch-service__box::before {
    font-size: 5em;
  }
}
.l-sketch-service__box__inner {
  display: flex;
  flex-direction: column-reverse;
  row-gap: 0.75em;
}
.l-sketch-service__box__txt {
  text-align: center;
  font-weight: 700;
  line-height: 1.5;
  font-size: calc(1rem + 8 * (100vw - 280px) / 1160);
}
@media (min-width: 768px) {
  .l-sketch-service__box__txt {
    font-size: calc(0.8rem + 6 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1553px) {
  .l-sketch-service__box__txt {
    font-size: 1.57rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .l-sketch-service__box__txt {
    font-size: 1.47rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .l-sketch-service__box__txt {
    font-size: 1.38rem;
  }
}
.l-sketch-service__box__img {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 320px;
}
.l-sketch-service__box__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
}
.l-sketch-service__box__img__inner::before {
  content: "";
  display: block;
  padding-top: 64.91%;
  position: relative;
}
.l-sketch-service__box__img__inner > div,
.l-sketch-service__box__img__inner figure,
.l-sketch-service__box__img__inner a,
.l-sketch-service__box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/* l-sketch-reserve */
.l-sketch-reserve {
  padding-top: clamp(2em, 4vw, 4em);
  padding-bottom: clamp(3em, 6vw, 6em);
  background: #FFF9E5;
}
@media (max-width: 767px) {
  .l-sketch-reserve {
    padding-bottom: clamp(7em, 12vw, 12em);
  }
}
.l-sketch-reserve .l-cont {
  position: relative;
}
.l-sketch-reserve__add {
  position: absolute;
}
@media (min-width: 768px) {
  .l-sketch-reserve__add {
    top: 0;
    width: 21.8%;
  }
}
@media (max-width: 767px) {
  .l-sketch-reserve__add {
    bottom: clamp(-7em, -12vw, -12em);
    width: 20vw;
  }
}
.l-sketch-reserve__add.is-add01 {
  left: 2%;
}
.l-sketch-reserve__add.is-add02 {
  right: 2%;
}
@media (min-width: 768px) {
  .l-sketch-reserve__inner {
    margin-left: auto;
    margin-right: auto;
    width: 56%;
  }
}
.l-sketch-reserve__tit {
  width: 94%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: clamp(0.75em, 1.5vw, 1.5em);
}
.l-sketch-reserve__txt {
  margin-bottom: 0.75em;
  text-align: center;
}
@media (min-width: 768px) {
  .l-sketch-reserve__txt {
    font-size: calc(1rem + 4 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1553px) {
  .l-sketch-reserve__txt {
    font-size: 1.71rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .l-sketch-reserve__txt {
    font-size: 1.6rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .l-sketch-reserve__txt {
    font-size: 1.5rem;
  }
}
.l-sketch-reserve__btn {
  margin-left: auto;
  margin-right: auto;
  max-width: 360px;
}
@media (min-width: 768px) {
  .l-sketch-reserve__btn {
    max-width: 700px;
  }
}
.l-sketch-reserve__btn a {
  font-weight: 500;
  display: block;
  text-align: center;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  display: grid;
  color: #fff;
  background-color: #E86607;
  border: 1px solid;
  border-color: #E86607;
  padding: 1em 3%;
  font-size: 1.25em;
  width: 100%;
  font-weight: 700;
  border-radius: 999px;
}
.l-sketch-reserve__btn a span {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 0.5em;
  align-items: center;
  transition: all 0.3s ease 0s;
}
.l-sketch-reserve__btn a span:after {
  content: "\e914";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.l-sketch-reserve__btn a span::before {
  content: "";
  justify-self: start;
}
.l-sketch-reserve__btn a span::after {
  justify-self: end;
  font-size: 1.25em;
  transition: all 0.3s ease 0s;
  color: #202020;
  width: 2em;
  height: 2em;
  line-height: 2em;
  border-radius: 50%;
  background: #fff;
}
.l-sketch-reserve__btn a:hover {
  color: #fff;
  background-color: #ed4d0e;
  border-color: #ed4d0e;
}
.l-sketch-reserve__btn a:hover span::after {
  color: #ed4d0e;
}
@media (min-width: 768px) {
  .l-sketch-reserve__btn a {
    font-size: calc(1.25rem + 10 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1553px) {
  .l-sketch-reserve__btn a {
    font-size: 2.29rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .l-sketch-reserve__btn a {
    font-size: 2.13rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .l-sketch-reserve__btn a {
    font-size: 2rem;
  }
}

/*--------------------------------------------------------------------/
	company
/--------------------------------------------------------------------*/
.company-common__tit {
  margin-bottom: clamp(1.5em, 2vw, 2em);
}
.company-common__tit b {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: #54BA00;
  display: block;
  line-height: 1.2;
  font-size: calc(2rem + 32 * (100vw - 280px) / 1160);
}
@media (min-width: 1553px) {
  .company-common__tit b {
    font-size: 4.57rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .company-common__tit b {
    font-size: 4.27rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .company-common__tit b {
    font-size: 4rem;
  }
}
.company-common__tit small {
  font-weight: 700;
  letter-spacing: 0.02em;
  color: #202020;
  display: block;
  line-height: 1.2;
  font-size: calc(1.25rem + 16 * (100vw - 280px) / 1160);
}
@media (min-width: 1553px) {
  .company-common__tit small {
    font-size: 2.57rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .company-common__tit small {
    font-size: 2.4rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .company-common__tit small {
    font-size: 2.25rem;
  }
}

.company-greeting {
  padding-bottom: clamp(3em, 6vw, 6em);
  overflow: hidden;
}
.company-greeting__inner {
  position: relative;
  padding: clamp(2em, 4vw, 4em) clamp(2em, 5%, 5em) clamp(3em, 4vw, 4em);
  background: #F2FBEC;
  border-top-left-radius: clamp(8px, 2.5vw, 40px);
  border-bottom-left-radius: clamp(8px, 2.5vw, 40px);
}
.company-greeting__inner::after {
  position: absolute;
  top: 0;
  left: calc(100% - 1px);
  content: "";
  width: 100vw;
  height: 100%;
  background: #F2FBEC;
}
.company-greeting__grid {
  display: grid;
}
@media (min-width: 768px) {
  .company-greeting__grid {
    grid-template-columns: 24% 1fr;
    grid-template-rows: auto 1fr;
    gap: 0 7%;
  }
}
@media (max-width: 767px) {
  .company-greeting__grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    gap: 0;
  }
}
@media (min-width: 768px) {
  .company-greeting__tit {
    margin-top: 1em;
    grid-column: 2/3;
    grid-row: 1/2;
  }
}
@media (max-width: 767px) {
  .company-greeting__tit {
    grid-row: 1;
  }
}
@media (min-width: 768px) {
  .company-greeting__txt {
    grid-column: 2/3;
    grid-row: 2/3;
  }
}
@media (min-width: 1553px) {
  .company-greeting__txt {
    font-size: 1.29rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .company-greeting__txt {
    font-size: 1.2rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .company-greeting__txt {
    font-size: 1.13rem;
  }
}
@media (max-width: 767px) {
  .company-greeting__txt {
    grid-row: 3;
  }
}
@media (min-width: 768px) {
  .company-greeting__pic {
    grid-column: 1/2;
    grid-row: 1/3;
  }
}
@media (max-width: 767px) {
  .company-greeting__pic {
    grid-row: 2;
    margin-bottom: 1em;
    max-width: 260px;
  }
}
.company-greeting__pic__inner {
  border-radius: clamp(4px, 1.5vw, 16px);
  overflow: hidden;
}
.company-greeting__pic figcaption {
  margin-top: 0.5em;
  font-weight: 500;
}
@media (min-width: 576px) {
  .company-greeting__pic figcaption {
    font-size: 1.07em;
  }
}

.company-qualification {
  padding-bottom: clamp(3em, 6vw, 6em);
  overflow: hidden;
}
.company-qualification__inner {
  position: relative;
  padding: clamp(2em, 4vw, 4em) clamp(2em, 5%, 5em) clamp(3em, 4vw, 4em);
  background: #F8F9FA;
  border-top-right-radius: clamp(8px, 2.5vw, 40px);
  border-bottom-right-radius: clamp(8px, 2.5vw, 40px);
}
.company-qualification__inner::after {
  position: absolute;
  top: 0;
  right: calc(100% - 1px);
  content: "";
  width: 100vw;
  height: 100%;
  background: #F8F9FA;
}
@media (min-width: 1176px) {
  .company-qualification__flex {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 1176px) {
  .company-qualification__tit {
    width: 46%;
  }
}
@media (min-width: 1176px) {
  .company-qualification__txt {
    width: 50%;
  }
}
.company-qualification__list li {
  line-height: 1.4;
  padding-left: 1.5em;
  position: relative;
  font-weight: 700;
}
@media (min-width: 768px) {
  .company-qualification__list li {
    font-size: 1.14em;
  }
}
@media (min-width: 1553px) {
  .company-qualification__list li {
    font-size: 1.43rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .company-qualification__list li {
    font-size: 1.33rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .company-qualification__list li {
    font-size: 1.25rem;
  }
}
.company-qualification__list li::before {
  content: "●";
  color: #54BA00;
  position: absolute;
  left: 0;
  top: 0.3em;
  font-size: 0.65em;
}
.company-qualification__list li:not(:last-child) {
  margin-bottom: 0.75em;
}

.company-profile {
  padding-bottom: clamp(5em, 8vw, 8em);
}
.company-profile__tit {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: clamp(2em, 3vw, 3em);
  display: flex;
  flex-direction: column;
  row-gap: 0.5em;
  text-align: center;
}
.company-profile__tit__add {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: clamp(80px, 10%, 136px);
}
.company-profile__tit__inner {
  font-weight: 700;
  font-size: calc(1.14rem + 16 * (100vw - 280px) / 1160);
}
@media (min-width: 1553px) {
  .company-profile__tit__inner {
    font-size: 2.57rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .company-profile__tit__inner {
    font-size: 2.4rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .company-profile__tit__inner {
    font-size: 2.25rem;
  }
}
.company-profile__list li {
  line-height: 1.4;
  padding: 1em 0 1em 1.5em;
  position: relative;
  border-bottom: 1px solid #D9D9D9;
}
@media (min-width: 1176px) {
  .company-profile__list li {
    font-size: 1.14em;
  }
}
.company-profile__list li::before {
  content: "●";
  color: #54BA00;
  position: absolute;
  left: 0;
  top: 1.8em;
  font-size: 0.65em;
}

.company-origin__inner {
  padding: clamp(2em, 4vw, 4em) clamp(2em, 5%, 5em) clamp(3em, 4vw, 4em);
  background: #F2FBEC;
  border-radius: clamp(8px, 2.5vw, 40px);
}
@media (min-width: 1176px) {
  .company-origin__inner {
    padding: clamp(2em, 4vw, 4em) clamp(2em, 7%, 8em) clamp(3em, 4vw, 4em);
  }
}
@media (min-width: 768px) {
  .company-origin__grid {
    display: grid;
    grid-template-columns: 32% 1fr;
    grid-template-rows: auto 1fr;
    gap: 0 6%;
  }
}
@media (min-width: 768px) {
  .company-origin__tit {
    grid-column: 2/3;
    grid-row: 1/2;
  }
}
@media (min-width: 768px) {
  .company-origin__word {
    grid-column: 1/2;
    grid-row: 1/3;
  }
}
@media (max-width: 767px) {
  .company-origin__word {
    margin-bottom: 1em;
  }
}
.company-origin__word li {
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.02em;
  font-family: 'Rubik', sans-serif;
  font-size: calc(1.5rem + 32 * (100vw - 280px) / 1160);
}
.company-origin__word li::first-letter {
  color: #54BA00;
  font-size: 1.25em;
}
@media (min-width: 768px) {
  .company-origin__word li {
    font-size: calc(2.5rem + 28 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1553px) {
  .company-origin__word li {
    font-size: 4.57rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .company-origin__word li {
    font-size: 4.27rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .company-origin__word li {
    font-size: 4rem;
  }
}
@media (min-width: 768px) {
  .company-origin__txt {
    grid-column: 2/3;
    grid-row: 2/3;
  }
}
@media (min-width: 1553px) {
  .company-origin__txt {
    font-size: 1.43rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .company-origin__txt {
    font-size: 1.33rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .company-origin__txt {
    font-size: 1.25rem;
  }
}

.company-overview {
  padding-top: clamp(5em, 8vw, 8em);
  padding-bottom: clamp(5em, 8vw, 8em);
}
.company-overview__inner {
  display: flex;
}
@media (min-width: 1176px) {
  .company-overview__inner {
    justify-content: space-between;
  }
}
@media (max-width: 1175px) {
  .company-overview__inner {
    flex-direction: column;
    row-gap: 1em;
  }
}
.company-overview__tit {
  margin-bottom: clamp(1.5em, 2vw, 2em);
}
.company-overview__tit b {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: #54BA00;
  display: block;
  line-height: 1.2;
  font-size: calc(1.5rem + 48 * (100vw - 280px) / 1160);
}
@media (min-width: 1176px) {
  .company-overview__tit b {
    font-size: calc(2.25rem + 20 * (100vw - 1080px) / 360);
  }
}
@media (min-width: 1553px) {
  .company-overview__tit b {
    font-size: 4rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .company-overview__tit b {
    font-size: 3.73rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .company-overview__tit b {
    font-size: 3.5rem;
  }
}
.company-overview__tit small {
  font-weight: 700;
  letter-spacing: 0.02em;
  color: #202020;
  display: block;
  line-height: 1.2;
  font-size: calc(1.25rem + 16 * (100vw - 280px) / 1160);
}
@media (min-width: 1176px) {
  .company-overview__tit small {
    font-size: calc(1.25rem + 8 * (100vw - 1080px) / 360);
  }
}
@media (min-width: 1553px) {
  .company-overview__tit small {
    font-size: 2rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .company-overview__tit small {
    font-size: 1.87rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .company-overview__tit small {
    font-size: 1.75rem;
  }
}
@media (min-width: 1176px) {
  .company-overview__tit {
    width: 25%;
  }
}
.company-overview__table {
  width: 100%;
}
@media (min-width: 1176px) {
  .company-overview__table {
    width: 73%;
  }
}

.company-access__inner {
  display: flex;
}
@media (min-width: 1176px) {
  .company-access__inner {
    justify-content: space-between;
  }
}
@media (max-width: 1175px) {
  .company-access__inner {
    flex-direction: column;
    row-gap: 1em;
  }
}
.company-access__tit {
  margin-bottom: clamp(1.5em, 2vw, 2em);
}
.company-access__tit b {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: #54BA00;
  display: block;
  line-height: 1.2;
  font-size: calc(2rem + 48 * (100vw - 280px) / 1160);
}
@media (min-width: 1176px) {
  .company-access__tit b {
    font-size: calc(2.25rem + 20 * (100vw - 1080px) / 360);
  }
}
@media (min-width: 1553px) {
  .company-access__tit b {
    font-size: 4rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .company-access__tit b {
    font-size: 3.73rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .company-access__tit b {
    font-size: 3.5rem;
  }
}
.company-access__tit small {
  font-weight: 700;
  letter-spacing: 0.02em;
  color: #202020;
  display: block;
  line-height: 1.2;
  font-size: calc(1.25rem + 16 * (100vw - 280px) / 1160);
}
@media (min-width: 1176px) {
  .company-access__tit small {
    font-size: calc(1.25rem + 8 * (100vw - 1080px) / 360);
  }
}
@media (min-width: 1553px) {
  .company-access__tit small {
    font-size: 2rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .company-access__tit small {
    font-size: 1.87rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .company-access__tit small {
    font-size: 1.75rem;
  }
}
@media (min-width: 1176px) {
  .company-access__tit {
    width: 25%;
  }
}
@media (min-width: 1176px) {
  .company-access__map {
    width: 73%;
  }
}
.company-access__map__inner {
  position: relative;
  border-radius: clamp(4px, 1.5vw, 16px);
  overflow: hidden;
}
.company-access__map__inner iframe {
  width: 100% !important;
  height: 100% !important;
  aspect-ratio: 4/3;
}
@media (min-width: 1176px) {
  .company-access__map__inner iframe {
    aspect-ratio: 2/1;
  }
}
.company-access__map__txt {
  margin-top: 0.75em;
}

/*--------------------------------------------------------------------/
	contact
/--------------------------------------------------------------------*/
.contact-cont {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.contact-flex {
  display: flex;
  margin-top: clamp(4em, 4vw, 4em);
}
@media (min-width: 768px) {
  .contact-flex {
    justify-content: space-between;
  }
  .contact-flex > * {
    flex-basis: 48.5%;
  }
}
@media (min-width: 1553px) {
  .contact-flex > * {
    flex-basis: 47.5%;
  }
}
@media (max-width: 767px) {
  .contact-flex {
    flex-direction: column;
    row-gap: clamp(3em, 4vw, 4em);
  }
}

.contact-box {
  text-align: center;
  padding: clamp(1.5em, 2vw, 2em) 4% clamp(2em, 2.5vw, 2.5em);
  background: #F2FBEC;
  position: relative;
  border-radius: clamp(6px, 2vw, 30px);
}
@media (min-width: 768px) {
  .contact-box {
    padding: clamp(2em, 3vw, 3em) 2em 3rem;
  }
}
@media (min-width: 1553px) {
  .contact-box {
    padding: clamp(2em, 3vw, 3em) 4.5em 3rem;
  }
}
.contact-box__icon {
  display: flex;
  justify-content: center;
  color: #fff;
  margin-bottom: 1.5em;
  margin-left: auto;
  margin-right: auto;
  font-weight: 100;
}
.contact-box__icon > * {
  position: absolute;
  bottom: calc(100% - 1.5em);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  font-size: 1.71rem;
  border-radius: clamp(4px, 1.5vw, 16px);
  background: #54BA00;
}
@media (min-width: 576px) {
  .contact-box__icon > * {
    font-size: 1.6rem;
  }
}
@media (min-width: 1176px) {
  .contact-box__icon > * {
    font-size: 1.5rem;
  }
}
@media (min-width: 768px) {
  .contact-box__icon > * {
    font-size: 2.14rem;
    width: 80px;
    height: 80px;
  }
}
@media (min-width: 768px) and (min-width: 576px) {
  .contact-box__icon > * {
    font-size: 2rem;
  }
}
@media (min-width: 768px) and (min-width: 1176px) {
  .contact-box__icon > * {
    font-size: 1.88rem;
  }
}
.contact-box__tit {
  margin-bottom: clamp(1em, 1.5vw, 1.5em);
  font-size: calc(1.14rem + 8 * (100vw - 280px) / 1160);
}
@media (min-width: 1176px) {
  .contact-box__tit {
    font-size: calc(1.25rem + 6 * (100vw - 1080px) / 360);
  }
}
@media (min-width: 1553px) {
  .contact-box__tit {
    font-size: 2rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .contact-box__tit {
    font-size: 1.87rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .contact-box__tit {
    font-size: 1.75rem;
  }
}
.contact-box__tel {
  font-family: 'Rubik', sans-serif;
  font-size: calc(1.5rem + 12 * (100vw - 280px) / 1160);
}
@media (min-width: 768px) {
  .contact-box__tel {
    font-size: calc(1.75rem + 12 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1553px) {
  .contact-box__tel {
    font-size: 2.86rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .contact-box__tel {
    font-size: 2.67rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .contact-box__tel {
    font-size: 2.5rem;
  }
}
.contact-box__tel i {
  font-size: 0.75em;
  margin-right: 0.3em;
  color: #54BA00;
}
.contact-box__tel a {
  color: #202020;
}

.contact-box__line {
  display: grid;
  grid-template-columns: 36% 1fr;
  grid-template-rows: auto auto;
  gap: 1em 5%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .contact-box__line {
    font-size: calc(0.7rem + 4 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1553px) {
  .contact-box__line {
    font-size: 1em;
  }
}
.contact-box__line__img {
  grid-column: 1/2;
  grid-row: 1/3;
}
.contact-box__line__btn {
  grid-column: 2/3;
  grid-row: 1/2;
}
.contact-box__line__btn a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0.5em 5%;
  background: #00B900;
  text-decoration: none !important;
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  font-size: 1.25em;
}
@media (min-width: 376px) {
  .contact-box__line__btn a {
    font-size: 1.5em;
  }
}
.contact-box__line__btn a i {
  font-weight: 400;
  margin-right: 0.2em;
}
.contact-box__line__id {
  grid-column: 2/3;
  grid-row: 2/3;
}
.contact-box__line__id__inner {
  width: 100%;
  padding: 0.5em 10%;
  background: #fff;
  display: flex;
  align-items: baseline;
  column-gap: 0.5em;
  font-size: 1em;
  border-radius: 5px;
}
@media (min-width: 376px) {
  .contact-box__line__id__inner {
    font-size: 1.37em;
  }
}
.contact-box__line__id__inner b {
  font-family: 'Rubik', sans-serif;
  letter-spacing: 0;
  color: #00B900;
  font-weight: 700;
  font-size: 1.2em;
}
.contact-box__line__id__inner span {
  font-weight: 700;
}

.contact-mail {
  margin-top: clamp(3em, 4vw, 4em);
  margin-bottom: clamp(4em, 6vw, 6em);
  text-align: center;
  padding: clamp(1.5em, 2vw, 2em) 4% clamp(2em, 2.5vw, 2.5em);
  background: #FFF9E5;
  position: relative;
  border-radius: clamp(6px, 2vw, 30px);
}
@media (min-width: 768px) {
  .contact-mail {
    margin-top: clamp(4em, 5vw, 5em);
    padding: clamp(2em, 3vw, 3em) 3rem 3rem;
  }
}
@media (min-width: 1553px) {
  .contact-mail {
    padding: clamp(2em, 3vw, 3em) 4.5em 3rem;
  }
}
.contact-mail__icon {
  display: flex;
  justify-content: center;
  color: #fff;
  margin-bottom: 1.5em;
  margin-left: auto;
  margin-right: auto;
  font-weight: 100;
}
.contact-mail__icon > * {
  position: absolute;
  bottom: calc(100% - 1.5em);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  font-size: 1.71rem;
  border-radius: clamp(4px, 1.5vw, 16px);
  background: #E86607;
}
@media (min-width: 576px) {
  .contact-mail__icon > * {
    font-size: 1.6rem;
  }
}
@media (min-width: 1176px) {
  .contact-mail__icon > * {
    font-size: 1.5rem;
  }
}
@media (min-width: 768px) {
  .contact-mail__icon > * {
    font-size: 2.14rem;
    width: 80px;
    height: 80px;
  }
}
@media (min-width: 768px) and (min-width: 576px) {
  .contact-mail__icon > * {
    font-size: 2rem;
  }
}
@media (min-width: 768px) and (min-width: 1176px) {
  .contact-mail__icon > * {
    font-size: 1.88rem;
  }
}
.contact-mail__tit {
  margin-bottom: clamp(1em, 1.5vw, 1.5em);
  font-size: calc(1.14rem + 12 * (100vw - 280px) / 1160);
}
@media (min-width: 1553px) {
  .contact-mail__tit {
    font-size: 2rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .contact-mail__tit {
    font-size: 1.87rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .contact-mail__tit {
    font-size: 1.75rem;
  }
}

/*--------------------------------------------------------------------/
	contact form
/--------------------------------------------------------------------*/
.common-form {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}
.common-form__tit {
  margin-bottom: clamp(2rem, 3vw, 3rem);
}
.common-form__tit b {
  font-family: 'Rubik', sans-serif;
  color: #54BA00;
  font-weight: 500;
  text-align: center;
  font-size: calc(1.75rem + 20 * (100vw - 280px) / 1160);
  letter-spacing: 0;
  display: block;
}
@media (min-width: 1553px) {
  .common-form__tit b {
    font-size: 3.43rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .common-form__tit b {
    font-size: 3.2rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .common-form__tit b {
    font-size: 3rem;
  }
}
.common-form__tit span {
  display: block;
  text-align: center;
  font-weight: 700;
  font-size: calc(1rem + 8 * (100vw - 280px) / 1160);
}
@media (min-width: 1553px) {
  .common-form__tit span {
    font-size: 1.71rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .common-form__tit span {
    font-size: 1.6rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .common-form__tit span {
    font-size: 1.5rem;
  }
}

.contact-step ol {
  counter-reset: contact-num;
  display: flex;
}
@media (min-width: 1176px) {
  .contact-step ol {
    max-width: 1040px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: space-between;
  }
  .contact-step ol > * {
    width: 18%;
    max-width: 160px;
    max-height: 160px;
  }
}
@media (max-width: 1175px) {
  .contact-step ol {
    flex-direction: column;
  }
}
.contact-step ol li {
  color: #202020;
  position: relative;
  font-size: 1rem;
}
@media (min-width: 576px) {
  .contact-step ol li {
    font-size: 0.93rem;
  }
}
@media (min-width: 1176px) {
  .contact-step ol li {
    font-size: 0.88rem;
  }
}
@media (min-width: 1176px) {
  .contact-step ol li {
    width: 160px;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #979DA4;
    text-align: center;
    background: #fff;
    border-radius: 50%;
  }
}
@media (max-width: 1175px) {
  .contact-step ol li:not(:last-child) {
    margin-bottom: 1rem;
  }
}
.contact-step ol li span {
  font-weight: 700;
}
@media (min-width: 1176px) {
  .contact-step ol li span::before {
    font-size: 2.57rem;
  }
}
@media (min-width: 1176px) and (min-width: 576px) {
  .contact-step ol li span::before {
    font-size: 2.4rem;
  }
}
@media (min-width: 1176px) and (min-width: 1176px) {
  .contact-step ol li span::before {
    font-size: 2.25rem;
  }
}
@media (max-width: 1175px) {
  .contact-step ol li span {
    display: flex;
    align-items: center;
    font-size: 1.28em;
  }
}
@media (max-width: 1175px) and (min-width: 576px) {
  .contact-step ol li span {
    font-size: 1.2em;
  }
}
@media (max-width: 1175px) and (min-width: 1176px) {
  .contact-step ol li span {
    font-size: 1.12em;
  }
}
@media (max-width: 1175px) {
  .contact-step ol li span::before {
    width: 60px;
    height: 60px;
    display: flex;
    margin-right: 1em;
    align-items: center;
    justify-content: center;
    border: 1px solid #E86607;
    text-align: center;
    background: #fff;
    font-size: 1.43rem;
    border-radius: 50%;
  }
}
@media (max-width: 1175px) and (min-width: 576px) {
  .contact-step ol li span::before {
    font-size: 1.33rem;
  }
}
@media (max-width: 1175px) and (min-width: 1176px) {
  .contact-step ol li span::before {
    font-size: 1.25rem;
  }
}
.contact-step ol li span::before {
  display: block;
  font-family: 'Zen Kaku Gothic New', sans-serif;
  counter-increment: contact-num 1;
  content: counter(contact-num, decimal-leading-zero);
  line-height: 1;
  margin-bottom: 0.3rem;
  color: #E86607;
}
.contact-step ol li:not(:last-child)::after {
  position: absolute;
  content: "";
  background: #979DA4;
}
@media (min-width: 1176px) {
  .contact-step ol li:not(:last-child)::after {
    left: 100%;
    width: 100%;
    height: 1px;
  }
}
@media (max-width: 1175px) {
  .contact-step ol li:not(:last-child)::after {
    top: 60px;
    left: 30px;
    width: 1px;
    height: 100%;
  }
}
@media (min-width: 1176px) {
  .contact-step ol li.now {
    background: #E86607;
    color: #fff;
    border-color: #E86607;
  }
}
.contact-step ol li.now span::before {
  color: #fff;
}
@media (max-width: 1175px) {
  .contact-step ol li.now span::before {
    background: #E86607;
  }
}
@media (min-width: 1176px) {
  .contact-step ol li.before {
    background: #f5b98e;
    color: #fff;
    border-color: #f5b98e;
  }
}
.contact-step ol li.before span::before {
  color: #fff;
}
@media (max-width: 1175px) {
  .contact-step ol li.before span::before {
    background: #f5b98e;
  }
}
@media (min-width: 1176px) {
  .contact-step ol li.before::after {
    border-color: transparent transparent transparent #f5b98e;
  }
}
@media (max-width: 1175px) {
  .contact-step ol li.before::after {
    border-color: #f5b98e transparent transparent transparent;
  }
}

.contact-privacy {
  border: 1px solid #979DA4;
}
@media (min-width: 1176px) {
  .contact-privacy {
    padding: clamp(1.5rem, 3vw, 3rem) 5% clamp(2rem, 3.5vw, 3rem);
    margin-top: 1.5rem;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #C9CED2 #fff;
    max-height: 300px;
  }
  .contact-privacy::-webkit-scrollbar {
    width: 8px;
  }
  .contact-privacy::-webkit-scrollbar-track {
    background: transparent;
  }
  .contact-privacy::-webkit-scrollbar-thumb {
    background: #C9CED2;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px #fff;
  }
}
@media (min-width: 1176px) {
  .contact-privacy__inner {
    margin-top: 1.5rem;
    padding: 0 5%;
  }
}
.contact-privacy__tit {
  font-size: calc(1.14rem + 4 * (100vw - 376px) / 1064);
  font-weight: 700;
  text-align: center;
  padding-bottom: clamp(1rem, 1.5vw, 1.5rem);
  position: relative;
}
@media (min-width: 1176px) {
  .contact-privacy__tit {
    margin-bottom: clamp(1rem, 2vw, 2rem);
  }
  .contact-privacy__tit::before {
    content: "";
    position: absolute;
    width: 4rem;
    height: 3px;
    bottom: 0;
    left: calc(50% - 2rem);
    border-radius: 2px;
    background: #54BA00;
  }
  .contact-privacy__tit .privacy-btn {
    display: none;
  }
}
@media (min-width: 1553px) {
  .contact-privacy__tit {
    font-size: 1.57rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .contact-privacy__tit {
    font-size: 1.47rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .contact-privacy__tit {
    font-size: 1.38rem;
  }
}
@media (max-width: 1175px) {
  .contact-privacy__tit {
    padding: 1.5rem 6%;
    position: relative;
  }
  .contact-privacy__tit .privacy-btn {
    cursor: pointer;
    display: block;
    position: absolute;
    top: calc(50% - 16px);
    right: 1rem;
    width: 32px;
    height: 32px;
    transition: all 0.3s ease 0s;
  }
  .contact-privacy__tit .privacy-btn i {
    font-size: 2.29rem;
    line-height: 1;
    color: #979DA4;
  }
}
@media (max-width: 1175px) and (min-width: 576px) {
  .contact-privacy__tit .privacy-btn i {
    font-size: 2.13rem;
  }
}
@media (max-width: 1175px) and (min-width: 1176px) {
  .contact-privacy__tit .privacy-btn i {
    font-size: 2rem;
  }
}
@media (max-width: 1175px) {
  .contact-privacy__tit .privacy-btn.active {
    transform: rotate(180deg);
  }
}
@media (max-width: 1175px) {
  .contact-privacy__txt {
    padding: 2em 5% clamp(2rem, 3.5vw, 3.5rem);
  }
}
.contact-privacy .txt {
  font-size: 1.07em;
}
@media (min-width: 576px) {
  .contact-privacy .txt {
    font-size: 1em;
  }
}
@media (min-width: 1176px) {
  .contact-privacy .txt {
    font-size: 0.93em;
  }
}

.contact-table {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  line-height: 1.2;
  position: relative;
}
.contact-table th, .contact-table td {
  text-align: left;
}
@media (min-width: 1176px) {
  .contact-table th, .contact-table td {
    display: table-cell;
    padding: 1rem 0 1.5rem;
  }
}
@media (max-width: 1175px) {
  .contact-table th, .contact-table td {
    display: block;
    padding: 0;
  }
}
.contact-table th {
  vertical-align: top;
  font-weight: 500;
}
@media (min-width: 1176px) {
  .contact-table th {
    width: 35%;
    line-height: 1.5;
  }
}
.contact-table th span {
  color: #fff;
  margin-left: 0.3rem;
  padding: 0.2em 0.75em;
  font-size: 0.8125em;
}
@media (min-width: 1176px) {
  .contact-table td {
    width: auto;
  }
}
@media (max-width: 1175px) {
  .contact-table td {
    padding: 1em 0 2em;
  }
}
.contact-table textarea {
  width: 100%;
}

input,
select {
  width: auto;
}
input.long,
select.long {
  width: 100%;
}
input.middle,
select.middle {
  width: 100%;
}
input.short,
select.short {
  width: 70%;
}
@media (min-width: 768px) {
  input.short,
  select.short {
    width: 30%;
  }
}
input.mini,
select.mini {
  width: 6em;
}

button {
  outline: none;
  font-size: 1em;
  letter-spacing: 0.1em;
}

button[class*=sub-btn],
button[class*=back-btn],
button[class*=chack-btn] {
  font-size: 1em;
  letter-spacing: 0.1em;
}

button[class*=sub-btn] {
  font-weight: 500;
  display: block;
  text-align: center;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  display: grid;
  color: #fff;
  background-color: #54BA00;
  border: 1px solid;
  border-color: #54BA00;
  padding: 1em 3%;
  font-size: 1.25em;
  width: 100%;
  font-weight: 700;
  border-radius: 999px;
}
button[class*=sub-btn] span {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 0.5em;
  align-items: center;
  transition: all 0.3s ease 0s;
}
button[class*=sub-btn] span:after {
  content: "\e914";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button[class*=sub-btn] span::before {
  content: "";
  justify-self: start;
}
button[class*=sub-btn] span::after {
  justify-self: end;
  font-size: 1.25em;
  transition: all 0.3s ease 0s;
  color: #202020;
  width: 2em;
  height: 2em;
  line-height: 2em;
  border-radius: 50%;
  background: #fff;
}
button[class*=sub-btn]:hover {
  color: #fff;
  background-color: #E86607;
  border-color: #E86607;
}
button[class*=sub-btn]:hover span::after {
  color: #E86607;
}

button[class*=back-btn] {
  margin-top: 1em;
  font-weight: 500;
  display: block;
  text-align: center;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  display: grid;
  color: #4B4D4E;
  background-color: #D9D9D9;
  border: 1px solid;
  border-color: #D9D9D9;
  padding: 1em 3%;
  font-size: 1.25em;
  width: 100%;
  font-weight: 700;
  border-radius: 999px;
}
button[class*=back-btn] span {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 0.5em;
  align-items: center;
  transition: all 0.3s ease 0s;
}
button[class*=back-btn] span:after {
  content: "\e914";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button[class*=back-btn] span::before {
  content: "";
  justify-self: start;
}
button[class*=back-btn] span::after {
  justify-self: end;
  font-size: 1.25em;
  transition: all 0.3s ease 0s;
  color: #4B4D4E;
  width: 2em;
  height: 2em;
  line-height: 2em;
  border-radius: 50%;
  background: #fff;
}
button[class*=back-btn]:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
button[class*=back-btn]:hover span::after {
  color: #000;
}

button[class*=chack-btn] {
  font-weight: 500;
  display: block;
  text-align: center;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  display: grid;
  color: #fff;
  background-color: #54BA00;
  border: 1px solid;
  border-color: #54BA00;
  padding: 1em 3%;
  font-size: 1.25em;
  width: 100%;
  font-weight: 700;
  border-radius: 999px;
}
button[class*=chack-btn] span {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 0.5em;
  align-items: center;
  transition: all 0.3s ease 0s;
}
button[class*=chack-btn] span:after {
  content: "\e914";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button[class*=chack-btn] span::before {
  content: "";
  justify-self: start;
}
button[class*=chack-btn] span::after {
  justify-self: end;
  font-size: 1.25em;
  transition: all 0.3s ease 0s;
  color: #202020;
  width: 2em;
  height: 2em;
  line-height: 2em;
  border-radius: 50%;
  background: #fff;
}
button[class*=chack-btn]:hover {
  color: #fff;
  background-color: #E86607;
  border-color: #E86607;
}
button[class*=chack-btn]:hover span::after {
  color: #E86607;
}

[type=button],
[type=submit] {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  display: block;
  margin-top: 1rem;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}
@media (min-width: 1921px) {
  [type=button],
  [type=submit] {
    max-width: 16.66vw;
  }
}

/* radio-box,check-box
----------------------------------------------------------------*/
/* variablea */
/* radio-btn */
.mwform-radio-field {
  cursor: pointer;
}
.mwform-radio-field:not(:last-child) {
  margin-right: 1em;
}

.mwform-radio-field-text {
  vertical-align: top;
  position: relative;
  padding-left: 34px;
  display: block;
}

input[type=radio] {
  position: absolute;
  opacity: 0;
}
input[type=radio] + .mwform-radio-field-text:before {
  content: "";
  background: #fcfcfc;
  border-radius: 100%;
  border: 1px solid #979DA4;
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: calc(50% - 20px / 2);
  left: 0;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 0.4s ease;
}
input[type=radio]:checked + .mwform-radio-field-text:before {
  background-color: #54BA00;
  box-shadow: inset 0 0 0 5px #F8F9FA;
}
input[type=radio]:focus + .mwform-radio-field-text:before {
  outline: none;
  border-color: #54BA00;
}
input[type=radio]:disabled + .mwform-radio-field-text:before {
  box-shadow: inset 0 0 0 4px #979DA4;
  border-color: #979DA4;
  background: #fcfcfc;
}
input[type=radio] + .mwform-radio-field-text:empty:before {
  margin-right: 0;
}

.vertical-item:not(:first-of-type) {
  margin-top: 1rem !important;
}

/* chack-btn */
@-moz-keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 13px;
  }
}
@-webkit-keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 13px;
  }
}
@keyframes dothabottomcheck {
  0% {
    height: 0;
  }
  100% {
    height: 13px;
  }
}
@keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 31.2px;
  }
}
@-webkit-keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 31.2px;
  }
}
@-moz-keyframes dothatopcheck {
  0% {
    height: 0;
  }
  50% {
    height: 0;
  }
  100% {
    height: 31.2px;
  }
}
input[type=checkbox] {
  display: none;
}

.check-box .vertical-item:not(:first-of-type) {
  margin-top: 1.5rem !important;
}
.check-box label {
  position: relative;
  cursor: pointer;
}
.check-box label::before {
  content: "";
  height: 26px;
  width: 26px;
  background-color: transparent;
  border: 1px solid #979DA4;
  margin-right: 1em;
  position: absolute;
  top: -0.15em;
  left: 0;
  display: block;
  transition: border-color ease 0.2s;
  z-index: 4;
}

.mwform-checkbox-field-text {
  vertical-align: top;
  padding-left: calc(26px + 1rem);
  display: block;
}
.mwform-checkbox-field-text::before, .mwform-checkbox-field-text::after {
  position: absolute;
  height: 0;
  width: 5.2px;
  background-color: #54BA00;
  display: inline-block;
  transform-origin: left top;
  content: "";
  transition: opacity ease 0.5;
  z-index: 3;
}
.mwform-checkbox-field-text::before {
  top: 22.36px;
  left: 10.4px;
  transform: rotate(-135deg);
}
.mwform-checkbox-field-text::after {
  top: 13px;
  left: 0.78px;
  transform: rotate(-45deg);
}

input[type=checkbox]:checked + .mwform-checkbox-field-text,
.mwform-checkbox-field-text.checked {
  border-color: #54BA00;
}
input[type=checkbox]:checked + .mwform-checkbox-field-text::after,
.mwform-checkbox-field-text.checked::after {
  height: 13px;
  animation: dothabottomcheck 0.2s ease 0s forwards;
}
input[type=checkbox]:checked + .mwform-checkbox-field-text::before,
.mwform-checkbox-field-text.checked::before {
  height: 31.2px;
  animation: dothatopcheck 0.4s ease 0s forwards;
  box-shadow: 0 0 0 1.3px #fff;
}

.mw_wp_form .error {
  margin-top: 0.7rem;
}

/* form-parts
----------------------------------------------------------------*/
select, textarea, input {
  background-color: #fcfcfc;
  padding: 0.8em 1em;
  border: 1px solid #C9CED2;
  line-height: 1.5;
}
select:focus, textarea:focus, input:focus {
  background-color: #fff;
  box-shadow: rgba(58, 134, 232, 0.25) 0 0 4px 1px;
}

input:-ms-input-placeholder, input:-moz-placeholder, input::-webkit-input-placeholder {
  color: #979DA4;
}

textarea {
  height: 10em;
}

/*--------------------------------------------------------------------/
	aside
/--------------------------------------------------------------------*/
.aside-cont__inner {
  display: flex;
  flex-direction: column;
  row-gap: clamp(2rem, 4vw, 4rem);
}

.aside-box h2 {
  padding: 0.75em 1em;
  margin-bottom: 0.5em;
  font-weight: 700;
  font-size: 1.25em;
  background: #FFF9E5;
  border-radius: 999px;
}
@media (min-width: 1176px) {
  .aside-box h2 {
    font-size: calc(1rem + 4 * (100vw - 1080px) / 360);
  }
}
@media (min-width: 1553px) {
  .aside-box h2 {
    font-size: 1.43rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .aside-box h2 {
    font-size: 1.33rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .aside-box h2 {
    font-size: 1.25rem;
  }
}

.aside-box__list,
.wpp-list,
.wp-widget-group__inner-blocks {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.wpp-list {
  counter-reset: popular-sidenum;
}
.wpp-list li {
  position: relative;
  margin-bottom: 0 !important;
}
.wpp-list li::before {
  position: absolute;
  top: 0.65em;
  left: 0;
  content: "";
  width: 1.75em;
  height: 1.75em;
  border-radius: 50%;
}
.wpp-list li:nth-of-type(1)::before {
  background: #DAB807;
}
.wpp-list li:nth-of-type(2)::before {
  background: #A7B5BE;
}
.wpp-list li:nth-of-type(3)::before {
  background: #AF6834;
}
.wpp-list li a {
  color: #202020;
  text-decoration: none !important;
  display: block;
  padding: 0.75em 0.25em 0.75em 2.5em;
  position: relative;
  border-bottom: 1px solid #D9D9D9;
}
@media (min-width: 1176px) {
  .wpp-list li a {
    font-size: 0.94em;
  }
}
.wpp-list li a::after {
  position: absolute;
  top: 0.55em;
  left: 0.45em;
  counter-increment: popular-sidenum;
  content: counter(popular-sidenum);
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  color: #fff;
  font-size: 1.14em;
}
.wpp-list li a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  transition: all 0.3s ease 0s;
  background: #54BA00;
}
.wpp-list li a:hover::before {
  width: 100%;
}

.select-display {
  margin-top: 1rem;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #C9CED2;
  padding: 1em;
  position: relative;
}
.select-display__btn {
  display: block;
  position: absolute;
  top: calc(50% - 16px);
  right: 1rem;
  width: 32px;
  height: 32px;
  transition: all 0.3s ease 0s;
}
.select-display__btn i {
  font-size: 2.29rem;
  line-height: 1;
  color: #979DA4;
}
@media (min-width: 576px) {
  .select-display__btn i {
    font-size: 2.13rem;
  }
}
@media (min-width: 1176px) {
  .select-display__btn i {
    font-size: 2rem;
  }
}
.select-display.is-active .select-display__btn {
  transform: rotate(180deg);
}
.select-display .is-text {
  display: block;
  width: 85%;
}

.select-list.is-active {
  position: relative;
  top: -1px;
  width: 100%;
  background: #fff;
  border: 1px solid #C9CED2;
  padding: 0.75em 1em 1.25em;
  z-index: 2;
}

.aside-box__list.is-category,
.wp-block-categories-list {
  display: flex;
  flex-direction: column;
}
.aside-box__list.is-category li a,
.wp-block-categories-list li a {
  color: #202020;
  text-decoration: none !important;
  display: block;
  padding: 0.75em 0.5em 0.75em 1.5em;
  position: relative;
  border-bottom: 1px solid #D9D9D9;
}
.aside-box__list.is-category li a::before,
.wp-block-categories-list li a::before {
  position: absolute;
  top: 1.3em;
  left: 0;
  content: "";
  width: 0.6em;
  height: 0.6em;
  border-radius: 50%;
  background: #54BA00;
}
.aside-box__list.is-category li a::after,
.wp-block-categories-list li a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  transition: all 0.3s ease 0s;
  background: #54BA00;
}
.aside-box__list.is-category li a:hover::after,
.wp-block-categories-list li a:hover::after {
  width: 100%;
}

.aside-box__list.is-recent li a,
.wp-block-archives-list li a,
.wp-block-latest-posts__list li a {
  color: #202020;
  text-decoration: none !important;
  display: block;
  padding: 0.75em 0.5em 0.75em 0;
  position: relative;
  border-bottom: 1px solid #D9D9D9;
}
@media (min-width: 1176px) {
  .aside-box__list.is-recent li a,
  .wp-block-archives-list li a,
  .wp-block-latest-posts__list li a {
    font-size: 0.94em;
  }
}
.aside-box__list.is-recent li a::before,
.wp-block-archives-list li a::before,
.wp-block-latest-posts__list li a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  transition: all 0.3s ease 0s;
  background: #54BA00;
}
.aside-box__list.is-recent li a:hover::before,
.wp-block-archives-list li a:hover::before,
.wp-block-latest-posts__list li a:hover::before {
  width: 100%;
}

.tag-cloud-link {
  font-size: 1rem !important;
  color: #202020;
  padding-left: 1.5rem;
  margin-right: 0.5rem;
  padding-bottom: 0.3em;
  position: relative;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
.tag-cloud-link:before {
  content: "\e904";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.tag-cloud-link::before {
  position: absolute;
  top: 0.6em;
  left: 0.2rem;
  font-size: 0.875em;
  color: #979DA4;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
.tag-cloud-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background: #54BA00;
  transition: all 0.3s ease 0s;
}
.tag-cloud-link:hover {
  color: #54BA00;
}
.tag-cloud-link:hover::before {
  color: #54BA00;
}
.tag-cloud-link:hover::after {
  width: 100%;
}

/* calendar
------------------------------------*/
#wp-calendar {
  border-collapse: separate;
  width: 100%;
}

.calendar_wrap {
  padding: 0.5rem 0.75rem;
  border: 1px solid #C9CED2;
}

.wp-calendar-table {
  margin-bottom: 0.75rem;
}
.wp-calendar-table caption {
  padding: 0.3rem 2%;
  font-weight: 500;
}
.wp-calendar-table thead th {
  text-align: center;
  padding: 6px;
  width: auto;
  color: #979DA4;
  font-weight: 500;
}
.wp-calendar-table td {
  text-align: center;
  background: #F8F9FA;
  line-height: 2;
  border: 2px solid #fff;
  font-size: 0.94em;
}
.wp-calendar-table td a {
  background-color: #54BA00;
  color: #fff;
  transition: all 0.3s ease 0s;
  display: block;
  text-decoration: none !important;
}
.wp-calendar-table td a:hover {
  background-color: #4CAE47;
}
.wp-calendar-table td.pad {
  background: transparent;
}
.wp-calendar-table td#today {
  background: #979DA4;
  color: #fff;
}
.wp-calendar-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 0.3rem;
}
.wp-calendar-nav a {
  display: block;
  color: #202020;
  padding: 0.2em 1rem;
  background-color: #F8F9FA;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
.wp-calendar-nav a:hover {
  background-color: #54BA00;
  color: #fff;
}

/*--------------------------------------------------------------------/
	search-form
/--------------------------------------------------------------------*/
.wp-block-search__inside-wrapper {
  position: relative;
  width: 100%;
  border: 1px solid #C9CED2 !important;
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wp-block-search__input {
  height: 44px;
  padding: 0 10px !important;
  outline: 0;
  background: #fff;
  appearance: none;
  border: none;
  width: calc(100% - 50px);
}

.wp-block-search__button {
  margin: 0;
  height: 44px !important;
  width: 50px !important;
  padding: 2px !important;
  background: #54BA00 !important;
  font-size: 18px !important;
  vertical-align: bottom !important;
  color: #fff !important;
  transition: all 0.3s ease 0s;
  border: none !important;
  text-align: center;
  cursor: pointer !important;
}
.wp-block-search__button:hover {
  color: #fff !important;
  background-color: #12A40A !important;
}

/*--------------------------------------------------------------------/
	archives
/--------------------------------------------------------------------*/
.wp-block-archives-dropdown {
  position: relative;
  margin-top: 1em;
}
.wp-block-archives-dropdown > label {
  display: none !important;
}
.wp-block-archives-dropdown select {
  position: relative;
  width: 100% !important;
}

select[name*=archive-dropdown] {
  position: relative;
  width: 90% !important;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
  position: relative;
  background: #EDFFE1;
  height: clamp(240px, 36vw, 320px);
}
.main-view:after {
  position: absolute;
  left: 0;
  bottom: -1px;
  content: "";
  width: 100%;
  height: 9.375vw;
  background: url(../img/common/wave_white_bottom.svg) bottom left/200% no-repeat;
  z-index: 2;
}
@media (min-width: 768px) {
  .main-view:after {
    width: 100%;
    height: 4.6875vw;
    background: url(../img/common/wave_white_bottom.svg) bottom center/cover no-repeat;
    z-index: 2;
  }
}
@media (min-width: 768px) {
  .main-view {
    height: clamp(320px, 30vw, 370px);
  }
}
@media (min-width: 1553px) {
  .main-view {
    height: 370px;
  }
}
@media (min-width: 1921px) {
  .main-view {
    height: 19.27vw;
  }
}
.main-view__add01 {
  position: absolute;
  width: 20.15%;
  top: clamp(90px, 14vw, 140px);
  left: 2%;
}
@media (min-width: 768px) {
  .main-view__add01 {
    top: clamp(100px, 10vw, 140px);
    left: 3%;
  }
}
@media (min-width: 1553px) {
  .main-view__add01 {
    top: 5rem;
    right: 72%;
  }
}
.main-view__add02 {
  position: absolute;
  width: 18.38%;
  top: clamp(90px, 14vw, 140px);
  right: 2%;
}
@media (min-width: 768px) {
  .main-view__add02 {
    top: 13vw;
    top: clamp(100px, 13vw, 160px);
    right: 4%;
  }
}
@media (min-width: 1553px) {
  .main-view__add02 {
    top: 7.5rem;
    left: 70%;
  }
}
.main-view .l-cont {
  position: relative;
  height: 100%;
}
.main-view__tit {
  text-align: center;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  color: #4CAE47;
  transform: translateY(-50%);
  font-weight: 700;
}
@media (min-width: 1553px) {
  .main-view__tit {
    top: 48%;
  }
}
.main-view__tit h1 {
  font-size: calc(1.25rem + 28 * (100vw - 280px) / 1160);
  font-weight: 700;
  line-height: 1.4;
}
@media (min-width: 1553px) {
  .main-view__tit h1 {
    font-size: 3.29rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .main-view__tit h1 {
    font-size: 3.07rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .main-view__tit h1 {
    font-size: 2.88rem;
  }
}
.main-view__tit h1 span.is-type {
  display: block;
  font-size: calc(1rem + 10 * (100vw - 280px) / 1160);
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1553px) {
  .main-view__tit h1 span.is-type {
    font-size: 1.71rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .main-view__tit h1 span.is-type {
    font-size: 1.6rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .main-view__tit h1 span.is-type {
    font-size: 1.5rem;
  }
}
.main-view__tit h1.is-single {
  padding-top: 1em;
  font-size: calc(1.14rem + 14 * (100vw - 280px) / 1160);
  text-shadow: 0 0 10px #fff;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1553px) {
  .main-view__tit h1.is-single {
    font-size: 2.29rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .main-view__tit h1.is-single {
    font-size: 2.13rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .main-view__tit h1.is-single {
    font-size: 2rem;
  }
}
.main-view__tit h1.is-404 {
  font-family: 'Rubik', sans-serif;
  font-weight: 500;
  font-size: calc(1.25rem + 28 * (100vw - 280px) / 1160);
}
@media (min-width: 1553px) {
  .main-view__tit h1.is-404 {
    font-size: 3.43rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .main-view__tit h1.is-404 {
    font-size: 3.2rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .main-view__tit h1.is-404 {
    font-size: 3rem;
  }
}
.main-view__tit__category {
  margin-top: 0.5em;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  justify-content: center;
}
@media (max-width: 575px) {
  .main-view__tit__category li span {
    font-size: 0.8em;
  }
}

/*--------------------------------------------------------------------/
	contents
/--------------------------------------------------------------------*/
.contents {
  padding-top: 1em;
}

/*--------------------------------------------------------------------/
	common-flex
/--------------------------------------------------------------------*/
.common-flex {
  width: 100%;
  position: relative;
  display: flex;
}
@media (min-width: 1176px) {
  .common-flex {
    justify-content: space-between;
  }
}
@media (max-width: 1175px) {
  .common-flex {
    flex-direction: column;
    row-gap: clamp(3rem, 5vw, 5rem);
  }
}
@media (min-width: 1176px) {
  .common-flex__main {
    width: 72%;
  }
}
@media (min-width: 1553px) {
  .common-flex__main {
    width: 74.16%;
  }
}
.common-flex__aside {
  position: relative;
}
@media (min-width: 1176px) {
  .common-flex__aside {
    width: 25%;
  }
}
@media (min-width: 1553px) {
  .common-flex__aside {
    width: 20.83%;
  }
}
@media (max-width: 1175px) {
  .common-flex__aside {
    width: 100%;
  }
}

.blog-popular {
  padding-top: clamp(2em, 3vw, 3em);
  padding-bottom: clamp(3em, 6vw, 6em);
  margin-bottom: clamp(3em, 6vw, 6em);
  border-bottom: 1px solid #D9D9D9;
}
.blog-popular__tit {
  position: relative;
  text-align: center;
  margin-bottom: clamp(3em, 2.5vw, 3em);
  color: #4CAE47;
  font-size: calc(1.25rem + 12 * (100vw - 280px) / 1160);
  font-weight: 700;
}
@media (min-width: 768px) {
  .blog-popular__tit {
    margin-bottom: clamp(2em, 2.5vw, 3em);
  }
}
@media (min-width: 1176px) {
  .blog-popular__tit {
    font-size: calc(1.75rem + 8 * (100vw - 1080px) / 360);
  }
}
@media (min-width: 1553px) {
  .blog-popular__tit {
    font-size: 2.57rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .blog-popular__tit {
    font-size: 2.4rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .blog-popular__tit {
    font-size: 2.25rem;
  }
}
.blog-popular__tit::before {
  position: absolute;
  left: 50%;
  bottom: calc(100% + 0.3em);
  transform: translateX(-50%);
  content: "";
  width: clamp(50px, 7.3vw, 74px);
  height: clamp(39px, 5.7vw, 58px);
  background: url(../img/common/icon_crown.svg) center center/contain no-repeat;
}
@media (min-width: 1176px) {
  .blog-popular .blog-box__popular span::after {
    font-size: clamp(2em, 2.5vw, 54px);
  }
}

.common-flex__list {
  display: flex;
}
.common-flex__list.is-col1 {
  flex-direction: column;
  row-gap: 2em;
}
@media (min-width: 768px) {
  .common-flex__list.is-col1 {
    row-gap: clamp(1em, 1.5vw, 1.5em);
  }
}
.common-flex__list.is-col3 {
  display: grid;
  row-gap: 2em;
}
@media (min-width: 576px) {
  .common-flex__list.is-col3 {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    column-gap: 4%;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}
@media (min-width: 768px) {
  .common-flex__list.is-col3 {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    column-gap: 2%;
  }
}
@media (min-width: 1176px) {
  .common-flex__list.is-col3 {
    grid-template-columns: repeat(auto-fit, minmax(260px, 32.33%));
    column-gap: 1.5%;
  }
}
@media (min-width: 1553px) {
  .common-flex__list.is-col3 {
    grid-template-columns: repeat(auto-fit, minmax(260px, 31.46%));
    column-gap: 2.8%;
  }
}
.common-flex__list.is-col3.popular-list {
  row-gap: 4.5em;
}
@media (min-width: 576px) {
  .common-flex__list.is-col3.popular-list {
    row-gap: clamp(3em, 4vw, 4em);
  }
}

/*--------------------------------------------------------------------/
	single-cont
/--------------------------------------------------------------------*/
.single-cont__main {
  padding-bottom: clamp(3em, 5vw, 5em);
  border-bottom: 1px dashed #54BA00;
}
.single-cont__main__img {
  border-radius: 5px;
  overflow: hidden;
}
.single-cont__main__textbox {
  padding-top: clamp(3em, 5vw, 5em);
}
.single-cont__main__textbox__tit {
  position: relative;
  padding-left: 1.5em;
  color: #4CAE47;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: clamp(0.75em, 1vw, 1em);
  font-size: calc(1.14rem + 8 * (100vw - 280px) / 1160);
}
@media (min-width: 1176px) {
  .single-cont__main__textbox__tit {
    font-size: calc(1.25rem + 4 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1553px) {
  .single-cont__main__textbox__tit {
    font-size: 1.86rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .single-cont__main__textbox__tit {
    font-size: 1.73rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .single-cont__main__textbox__tit {
    font-size: 1.63rem;
  }
}
.single-cont__main__textbox__tit::before {
  position: absolute;
  top: 0.6em;
  left: 0;
  content: "";
  width: 1em;
  height: 0.2em;
  background: #4CAE47;
  border-radius: 999px;
}

.single-cont__canva {
  padding: clamp(2.5em, 3vw, 3rem) 0 clamp(3em, 4vw, 4rem);
  border-bottom: 1px dashed #54BA00;
}

.single-cont__pdf {
  padding: clamp(2.5em, 3vw, 3rem) 0 clamp(3em, 4vw, 4rem);
}
.single-cont__pdf__list {
  display: flex;
}
@media (min-width: 1176px) {
  .single-cont__pdf__list {
    flex-wrap: wrap;
    column-gap: 2%;
    row-gap: 1em;
  }
  .single-cont__pdf__list > * {
    width: 49%;
  }
}
@media (max-width: 1175px) {
  .single-cont__pdf__list {
    flex-direction: column;
    row-gap: 0.5em;
  }
}
.single-cont__pdf__list li a {
  padding: 1.25em 4% 1.25em 4.5em;
  transition: all 0.3s ease 0s;
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  line-height: 1.4;
  color: #202020;
  background: #F2FBEC;
  text-decoration: none !important;
  font-weight: 700;
  border-radius: 999px;
}
.single-cont__pdf__list li a:before {
  content: "\e903";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.single-cont__pdf__list li a::before {
  position: absolute;
  left: 1em;
  top: 0.6em;
  font-size: 1.8em;
  color: #4CAE47;
  transition: all 0.3s ease 0s;
}
.single-cont__pdf__list li a:hover {
  background: #12A40A;
  color: #fff;
}
.single-cont__pdf__list li a:hover::before {
  color: #fff;
}

.single-cont__interview {
  padding-bottom: clamp(3rem, 7vw, 7rem);
}
.single-cont__interview__list {
  display: flex;
  flex-direction: column;
  row-gap: clamp(3em, 5vw, 5em);
}

.single-cont__interview__box__flex {
  display: flex;
}
@media (min-width: 768px) {
  .single-cont__interview__box__flex {
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  .single-cont__interview__box__flex {
    flex-direction: column;
    row-gap: 1em;
  }
}
@media (min-width: 768px) {
  .single-cont__interview__box__flex.is-right {
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .single-cont__interview__box__flex.is-left {
    flex-direction: row-reverse;
  }
}
.single-cont__interview__box__tit {
  position: relative;
  font-size: calc(1.14rem + 8 * (100vw - 280px) / 1160);
  padding-bottom: 0.7em;
  margin-bottom: calc(0.5rem + 0.75em);
  line-height: 1.5;
  font-weight: 700;
}
@media (min-width: 1176px) {
  .single-cont__interview__box__tit {
    font-size: calc(1.14rem + 6 * (100vw - 768px) / 672);
  }
}
@media (min-width: 1553px) {
  .single-cont__interview__box__tit {
    font-size: 1.71rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .single-cont__interview__box__tit {
    font-size: 1.6rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .single-cont__interview__box__tit {
    font-size: 1.5rem;
  }
}
.single-cont__interview__box__tit::before {
  content: "";
  width: 100%;
  height: 3px;
  background: #FDD035;
  border-radius: 6px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}
@media (min-width: 768px) {
  .single-cont__interview__box__txt {
    flex-basis: 55%;
  }
}
@media (min-width: 1176px) {
  .single-cont__interview__box__txt {
    flex-basis: 51.25%;
  }
}
@media (min-width: 768px) {
  .single-cont__interview__box__img {
    flex-basis: 40%;
  }
}
@media (min-width: 1176px) {
  .single-cont__interview__box__img {
    flex-basis: 42.87%;
  }
}
.single-cont__interview__box__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  border-radius: 5px;
  overflow: hidden;
}
.single-cont__interview__box__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.single-cont__interview__box__img__inner > div,
.single-cont__interview__box__img__inner figure,
.single-cont__interview__box__img__inner a,
.single-cont__interview__box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.single-cont__interview__box__img figcaption {
  margin-top: 0.5rem;
  line-height: 1.4;
  z-index: 1;
  padding-left: 1.5rem;
  position: relative;
}
.single-cont__interview__box__img figcaption::before {
  content: "●";
  position: absolute;
  top: 0;
  left: 0;
  color: #E86607;
}

.single-cont__video {
  padding: clamp(1.5em, 3vw, 3em) clamp(1.5em, 5%, 4em) clamp(2em, 3vw, 3em);
  background: #F2FBEC;
  border-radius: clamp(6px, 2vw, 30px);
  margin-bottom: clamp(3rem, 7vw, 7rem);
}
.single-cont__video__tit {
  position: relative;
  font-weight: 700;
  line-height: 1.4;
  font-size: calc(1.14rem + 12 * (100vw - 280px) / 1160);
  text-align: center;
  margin-bottom: clamp(1.25em, 2vw, 1.75rem);
  padding-bottom: clamp(0.75em, 1vw, 1rem);
}
@media (min-width: 1553px) {
  .single-cont__video__tit {
    font-size: 2rem;
  }
}
@media (min-width: 1553px) and (min-width: 576px) {
  .single-cont__video__tit {
    font-size: 1.87rem;
  }
}
@media (min-width: 1553px) and (min-width: 1176px) {
  .single-cont__video__tit {
    font-size: 1.75rem;
  }
}
.single-cont__video__tit::after {
  position: absolute;
  bottom: 0;
  left: calc(50% - 1em);
  content: "";
  width: 2em;
  height: 0.2em;
  border-radius: 10px;
  background: #4CAE47;
}
@media (min-width: 1176px) {
  .single-cont__video__inner {
    display: grid;
    grid-template-columns: 1fr 40%;
    grid-template-rows: auto 1fr;
    column-gap: 7%;
    row-gap: clamp(1rem, 1.5vw, 2rem);
  }
}
@media (min-width: 1176px) {
  .single-cont__video__video {
    grid-column: 2/3;
    grid-row: 1/2;
  }
}
@media (max-width: 1175px) {
  .single-cont__video__video {
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;
    margin-bottom: 1em;
  }
}
.single-cont__video__video__inner {
  position: relative;
  width: 100%;
  height: 0;
  border-radius: 5px;
  overflow: hidden;
  padding-bottom: 56.25%;
}
.single-cont__video__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 1176px) {
  .single-cont__video__txt {
    grid-column: 1/2;
    grid-row: 1/2;
  }
}

.single-cont__foot__date {
  padding-top: 1em;
  padding-bottom: 1em;
  text-align: right;
  font-weight: 500;
  color: #6F757B;
}