@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	about
/--------------------------------------------------------------------*/
// about-intro
.about-intro {
	padding-top: clamp(2em, 3vw, 3em);
	padding-bottom: clamp(7em, 20vw, 20em);
	@include bg-line;
	&__inner {
		width: 100%;
		background: $white;
		padding: clamp(2em, 3vw, 3em) clamp(1.5em, 5%, 5em) clamp(2.5em, 4vw, 4em);
		box-shadow: 0 0 10px rgba($black, 0.05);
		@include radius_l;
		@include media-breakpoint-up(md) {
			padding: clamp(2em, 4.5vw, 5em) clamp(1.5em, 5%, 5em) clamp(2.5em, 5vw, 6em);
		}
	}
	&__flex {
		display: flex;
		@include media-breakpoint-up(md) {
			justify-content: space-between;
			align-items: center;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: 0.5em;
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			width: 54%;
		}
		&__tit {
			color: $sub_c;
		}
		&__txt {
			@include media-breakpoint-up(xl) {
				@include f-size(20);
			}
		}
	}
	&__img {
		@include media-breakpoint-up(md) {
			width: 32.43%;
		}
		@include media-breakpoint-down(sm) {
			@include flex-end;
			& > div {
				width: 40%;
			}
		}
	}
}

// about-video
.about-video {
	position: relative;
	margin-top: -10vw;
	@include media-breakpoint-up(xl) {
		margin-top: -12rem;
	}
	&::before {
		position: absolute;
		bottom: 0;
		left: 0;
		content: "";
		width: 100%;
		height: 20%;
		@include bg-orange;
	}
	&__inner {
		@include m-a;
		max-width: 1300px;
	}
	&__catch {
		width: 90%;
		@include m-a;
		position: relative;
		z-index: z-index(module, part02);
		&__inner {
			position: relative;
			padding: clamp(1em, 2.5vw, 2.5em) 5%;
			@include f-s_xxs(1, 12);
			@include f-w(700);
			color: $white;
			background: $main_c;
			@include radius_m;
			@include media-breakpoint-up(md) {
				text-align: center;
			}
			@include media-breakpoint-up(xl) {
				@include f-size(32);
			}
			&::after {
				position: absolute;
				top: calc(100% - 1px);
				left: calc(50% - 1em);
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 1.5em 1em 0 1em;
				border-color: $main_c transparent transparent transparent;
			}
		}
	}
	&__txt {
		position: relative;
		width: 100%;
		padding: clamp(6em, 9vw, 9em) 5% clamp(1.5em, 3vw, 3em);
		background: $white;
		margin-top: -4em;
		@include radius_m;
		box-shadow: 0 0 10px rgba($black, 0.1);
		&__video {
			max-width: 960px;
			@include m-a;
			iframe {
				width: 100%;
				height: 100%;
				aspect-ratio: 16 / 9;
			}
		}
		&__tit {
			margin-top: 1em;
			text-align: center;
			span {
				display: inline-block;
				background: $other_c4;
				padding: 0.5em 1.25em;
				@include radius;
				@include f-s_xxs(0.94, 6);
				@include f-w(700);
				@include media-breakpoint-up(xl) {
					@include f-size(24);
				}
			}
		}
	}
}

// about-list
.about-list {
	padding-top: clamp(6em, 9vw, 9em);
	margin-bottom: 1em;
	@include bg-orange;
	@include flex-column;
	row-gap: clamp(2em, 4vw, 4em);
}

.about-box {
	position: relative;
	&::before {
		@include media-breakpoint-up(md) {
			position: absolute;
			top: 0;
			content: "";
			width: 90%;
			height: 100%;
			background: $white;
		}
		@include media-breakpoint-up(xl) {
			width: calc(50% + 720px);
		}
	}
	&:nth-of-type(odd) {
		&::before {
			@include media-breakpoint-up(md) {
				right: 0;
				border-top-left-radius: clamp(8px, 2.5vw, 40px);
				border-bottom-left-radius: clamp(8px, 2.5vw, 40px);
			}
		}
	}
	&:nth-of-type(even) {
		&::before {
			@include media-breakpoint-up(md) {
				left: 0;
				border-top-right-radius: clamp(8px, 2.5vw, 40px);
				border-bottom-right-radius: clamp(8px, 2.5vw, 40px);
			}
		}
		.l-cont_xxl {
			@include media-breakpoint-up(md) {
				@include flex-end;
			}
		}
		.about-box__inner {
			@include media-breakpoint-up(md) {
				flex-direction: row-reverse;
			}
		}
	}
}
.about-box {
	&__inner {
		position: relative;
		display: flex;
		max-width: 1540px;
		width: 100%;
		z-index: z-index(module, part01);
		@include media-breakpoint-up(md) {
			padding-top: clamp(3em, 6vw, 6em);
			padding-bottom: clamp(3em, 6vw, 6em);
			column-gap: 5%;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: 1em;
			padding: 0 map-get($contmargin, sp) clamp(1.5em,2.5vw,2.5em);
		}
		&::before {
			@include media-breakpoint-down(sm) {
				position: absolute;
				left: 0;
				bottom: 0;
				content: "";
				width: 100%;
				height: calc(100% - 25vw);
				background: $white;
				@include radius_m;
				z-index: -1;
			}
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			width: 65%;
		}
		@include media-breakpoint-up(xl) {
			width: 52%;
		}
		&__tit {
			color: $sub_c;
			@include f-s_xxs(1.14, 14);
			margin-bottom: clamp(0.75em, 1.5vw, 1.25em);
			@include media-breakpoint-up(md) {
				@include f-s_md(1.37, 10);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(32);
			}
		}
		&__txt {
			@include media-breakpoint-up(xl) {
				@include f-size(18);
			}
			.txt {
				ul {
					margin-bottom: 1em;
					li {
						position: relative;
						padding-left: 1em;
						position: relative;
						&::before {
							position: absolute;
							top: 0;
							left: 0;
							content: "・";
							font-size: 1em;
						}
					}
				}
			}
		}
	}
	&__img {
		@include media-breakpoint-up(md) {
			margin-top: 2em;
			width: 30%;
		}
		@include media-breakpoint-up(xl) {
			width: 43%;
		}
		@include media-breakpoint-down(sm) {
			width: 86%;
			@include m-a;
		}
		&__inner {
			@include aspect-img(17, 13);
		}
	}
}
