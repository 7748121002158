//===================================================
//	font setting
//===================================================
//font-size
$f-sp:14; //スマホ用フォントサイズ
$f-tb:15; //タブレット用フォントサイズ
$f-pc:16; //PC用フォントサイズ


//font-family
$font-set:(
	font01:"'Zen Kaku Gothic New'", //メインフォントファミリー
	font02:"'Rubik'", //英字フォントファミリー
	font03:"'Abril Fatface'", //その他ファミリー
);


/* webfont 変数　*/
$icons: (
  arrow1_top: "\e316",
  arrow1_right: "\e315",
  arrow1_bottom: "\e313",
  arrow1_left: "\e314",
  arrow2_top: "\e907",
  arrow2_right: "\e901",
  arrow2_bottom: "\e906",
  arrow2_left: "\e902",
  direction_arrow_top: "\e913",
  direction_arrow_right: "\e914",
  direction_arrow_bottom: "\e915",
  direction_arrow_left: "\e916",
  home: "\e90c",
  phone: "\e909",
  mail: "\e911", 
  search: "\e905",
  search-plus: "\e908",
  link: "\e900",
  pdf: "\e903",
  tag: "\e904",
  human: "\e918",
  lock: "\e90f",
  facebook1: "\e90a",
  facebook2: "\e912",
  x: "\e90b",
  instagram: "\e90d",
  Line: "\e90e",
  youtube: "\e910",
  tiktok: "\e917",
) !default;

@mixin icon($icon: false, $position:before) {
  @if $position == both {
    $position: ":before,:after";
  }
  &:#{$position} {
    @if $icon {
      content: "#{map-get($icons, $icon)}";
      font-family: "icomoon" !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
  @content;
}