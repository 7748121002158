@use "../_variables/variables" as *;
@use "effects" as *;
@use "others" as *;
@use "text" as *;

@mixin btn_base {
	@include f-w(500);
	display: block;
	text-align: center;
	text-decoration: none;
	position: relative;
	line-height: 1.4;
	@include transition;
	@include dec-none;
}

@mixin btn_normal(
	$color: $white,
	$bg: $main_c,
	$border: $main_c,
	$color_h: $white,
	$bg_h: $point_c,
	$border_h: $point_c
) {
	@include btn_base;
	color: $color;
	background-color: $bg;
	border: 1px solid;
	border-color: $border;
	padding: 1rem 4%;
	width: 100%;
	&:hover {
		background-color: $color_h;
		background-color: $bg_h;
		border-color: $border_h;
	}
}

//矢印あり
@mixin btn_arrow(
	$color: $white,
	$bg: $point_c,
	$border: $point_c,
	$arrow: $txt_c,
	$color_h: $white,
	$bg_h: $point_ch,
	$border_h: $point_ch,
	$arrow_h: $point_ch
) {
	@include btn_base;
	display: grid;
	color: $color;
	background-color: $bg;
	border: 1px solid;
	border-color: $border;
	padding: 1em 3%;
	font-size: 1.25em;
	width: 100%;
	@include f-w(700);
	@include radius(999px);
	span {
		display: grid;
		grid-template-columns: 1fr auto 1fr;
		column-gap: 0.5em;
		align-items: center;
		@include transition;
		@include icon(direction_arrow_right, after);
		&::before {
			content: "";
			justify-self: start;
		}
		&::after {
			justify-self: end;
			font-size: 1.25em;
			@include transition;
			color: $arrow;
			width: 2em;
			height: 2em;
			line-height: 2em;
			@include radius(50%);
			background: $white;
		}
	}
	&:hover {
		color: $color_h;
		background-color: $bg_h;
		border-color: $border_h;
		& span::after {
			color: $arrow_h;
		}
	}
}
