@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	company
/--------------------------------------------------------------------*/

.company-common__tit {
	margin-bottom: clamp(1.5em, 2vw, 2em);
	b {
		@include f-family(font02);
		@include f-w(500);
		@include l-sp(0.02em);
		color: $main_c;
		display: block;
		@include line-h(1.2);
		@include f-s_xxs(2, 32);
		@include media-breakpoint-up(xl) {
			@include f-size(64);
		}
	}
	small {
		@include f-w(700);
		@include l-sp(0.02em);
		color: $txt_c;
		display: block;
		@include line-h(1.2);
		@include f-s_xxs(1.25, 16);
		@include media-breakpoint-up(xl) {
			@include f-size(36);
		}
	}
}

// company-greeting
.company-greeting {
	padding-bottom: clamp(3em, 6vw, 6em);
	overflow: hidden;
	&__inner {
		position: relative;
		padding: clamp(2em, 4vw, 4em) clamp(2em, 5%, 5em) clamp(3em, 4vw, 4em);
		background: $other_c2;
		border-top-left-radius: clamp(8px, 2.5vw, 40px);
		border-bottom-left-radius: clamp(8px, 2.5vw, 40px);
		&::after {
			position: absolute;
			top: 0;
			left: calc(100% - 1px);
			content: "";
			width: 100vw;
			height: 100%;
			background: $other_c2;
		}
	}
	&__grid {
		display: grid;
		@include media-breakpoint-up(md) {
			grid-template-columns: 24% 1fr;
			grid-template-rows: auto 1fr;
			gap: 0 7%;
		}
		@include media-breakpoint-down(sm) {
			grid-template-columns: 1fr;
			grid-template-rows: auto auto auto;
			gap: 0;
		}
	}
	&__tit {
		@include media-breakpoint-up(md) {
			margin-top: 1em;
			grid-column: 2 / 3;
			grid-row: 1 / 2;
		}
		@include media-breakpoint-down(sm) {
			grid-row: 1;
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			grid-column: 2 / 3;
			grid-row: 2 / 3;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
		@include media-breakpoint-down(sm) {
			grid-row: 3;
		}
	}
	&__pic {
		@include media-breakpoint-up(md) {
			grid-column: 1 / 2;
			grid-row: 1 / 3;
		}
		@include media-breakpoint-down(sm) {
			grid-row: 2;
			margin-bottom: 1em;
			max-width: 260px;
		}
		&__inner {
			@include radius_s;
			overflow: hidden;
		}
		figcaption {
			margin-top: 0.5em;
			@include f-w(500);
			@include media-breakpoint-up(sm) {
				font-size: 1.07em;
			}
		}
	}
}

// company-qualification
.company-qualification {
	padding-bottom: clamp(3em, 6vw, 6em);
	overflow: hidden;
	&__inner {
		position: relative;
		padding: clamp(2em, 4vw, 4em) clamp(2em, 5%, 5em) clamp(3em, 4vw, 4em);
		background: $p-gray;
		border-top-right-radius: clamp(8px, 2.5vw, 40px);
		border-bottom-right-radius: clamp(8px, 2.5vw, 40px);
		&::after {
			position: absolute;
			top: 0;
			right: calc(100% - 1px);
			content: "";
			width: 100vw;
			height: 100%;
			background: $p-gray;
		}
	}
	&__flex {
		@include media-breakpoint-up(lg) {
			display: flex;
			justify-content: space-between;
		}
	}
	&__tit {
		@include media-breakpoint-up(lg) {
			width: 46%;
		}
	}
	&__txt {
		@include media-breakpoint-up(lg) {
			width: 50%;
		}
	}
	&__list {
		li {
			line-height: 1.4;
			padding-left: 1.5em;
			position: relative;
			@include f-w(700);
			@include media-breakpoint-up(md) {
				font-size: 1.14em;
			}
			@include media-breakpoint-up(xl) {
				@include f-size(20);
			}
			&::before {
				content: "●";
				color: $main_c;
				position: absolute;
				left: 0;
				top: 0.3em;
				font-size: 0.65em;
			}
			&:not(:last-child) {
				margin-bottom: 0.75em;
			}
		}
	}
}

// company-profile
.company-profile {
	padding-bottom: clamp(5em, 8vw, 8em);
	&__tit {
		@include m-a;
		margin-bottom: clamp(2em,3vw,3em);
		@include flex-column;
		row-gap: 0.5em;
		text-align: center;
		&__add {
			display: block;
			@include m-a;
			width: clamp(80px,10%,136px);
		}
		&__inner {
			@include f-w(700);
			@include f-s_xxs(1.14,16);
			@include media-breakpoint-up(xl) {
				@include f-size(36);
			}
		}
	}
	&__list {
		li {
			line-height: 1.4;
			padding: 1em 0 1em 1.5em;
			position: relative;
			border-bottom: 1px solid $l-gray;
			@include media-breakpoint-up(lg) {
				font-size: 1.14em;
			}
			&::before {
				content: "●";
				color: $main_c;
				position: absolute;
				left: 0;
				top: 1.8em;
				font-size: 0.65em;
			}
		}
	}
}

// company-origin
.company-origin {
	&__inner {
		padding: clamp(2em, 4vw, 4em) clamp(2em, 5%, 5em) clamp(3em, 4vw, 4em);
		background: $other_c2;
		@include radius_l;
		@include media-breakpoint-up(lg) {
			padding: clamp(2em, 4vw, 4em) clamp(2em, 7%, 8em) clamp(3em, 4vw, 4em);
		}
	}
	&__grid {
		@include media-breakpoint-up(md) {
			display: grid;
			grid-template-columns: 32% 1fr;
			grid-template-rows: auto 1fr;
			gap: 0 6%;
		}
	}
	&__tit {
		@include media-breakpoint-up(md) {
			grid-column: 2 / 3;
			grid-row: 1 / 2;
		}
	}
	&__word {
		@include media-breakpoint-up(md) {
			grid-column: 1 / 2;
			grid-row: 1 / 3;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: 1em;
		}
		li {
			@include line-h(1);
			@include f-w(500);
			@include l-sp(0.02em);
			@include f-family(font02);
			@include f-s_xxs(1.5,32);
			&::first-letter {
				color: $main_c;
				font-size: 1.25em;
			}
			@include media-breakpoint-up(md) {
				@include f-s_md(2.5, 28);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(64);
			}
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			grid-column: 2 / 3;
			grid-row: 2 / 3;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
	}
}

// company-overview
.company-overview {
	padding-top: clamp(5em, 8vw, 8em);
	padding-bottom: clamp(5em, 8vw, 8em);
	&__inner {
		display: flex;
		@include media-breakpoint-up(lg) {
			justify-content: space-between;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: 1em;
		}
	}
	&__tit {
		margin-bottom: clamp(1.5em, 2vw, 2em);
		b {
			@include f-family(font02);
			@include f-w(500);
			@include l-sp(0.02em);
			color: $main_c;
			display: block;
			@include line-h(1.2);
			@include f-s_xxs(1.5, 48);
			@include media-breakpoint-up(lg) {
				@include f-s_lg(2.25, 20);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(56);
			}
		}
		small {
			@include f-w(700);
			@include l-sp(0.02em);
			color: $txt_c;
			display: block;
			@include line-h(1.2);
			@include f-s_xxs(1.25, 16);
			@include media-breakpoint-up(lg) {
				@include f-s_lg(1.25, 8);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(28);
			}
		}
		@include media-breakpoint-up(lg) {
			width: 25%;
		}
	}
	&__table {
		width: 100%;
		@include media-breakpoint-up(lg) {
			width: 73%;
		}
	}
}

// company-access
.company-access {
	&__inner {
		display: flex;
		@include media-breakpoint-up(lg) {
			justify-content: space-between;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: 1em;
		}
	}
	&__tit {
		margin-bottom: clamp(1.5em, 2vw, 2em);
		b {
			@include f-family(font02);
			@include f-w(500);
			@include l-sp(0.02em);
			color: $main_c;
			display: block;
			@include line-h(1.2);
			@include f-s_xxs(2, 48);
			@include media-breakpoint-up(lg) {
				@include f-s_lg(2.25, 20);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(56);
			}
		}
		small {
			@include f-w(700);
			@include l-sp(0.02em);
			color: $txt_c;
			display: block;
			@include line-h(1.2);
			@include f-s_xxs(1.25, 16);
			@include media-breakpoint-up(lg) {
				@include f-s_lg(1.25, 8);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(28);
			}
		}
		@include media-breakpoint-up(lg) {
			width: 25%;
		}
	}
	&__map {
		@include media-breakpoint-up(lg) {
			width: 73%;
		}
		&__inner {
			position: relative;
			@include radius_s;
			overflow: hidden;
			iframe {
				width: 100% !important;
				height: 100% !important;
				aspect-ratio: 4 / 3;
				@include media-breakpoint-up(lg) {
					aspect-ratio: 2 / 1;
				}
			}
		}
		&__txt {
			margin-top: 0.75em;
		}
	}
}
