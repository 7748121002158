@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	aside
/--------------------------------------------------------------------*/
.aside-cont {
	&__inner {
		@include flex-column;
		row-gap: clamp(2rem, 4vw, 4rem);
	}
}

.aside-box {
	& h2 {
		padding: 0.75em 1em;
		margin-bottom: 0.5em;
		@include f-w(700);
		font-size: 1.25em;
		background: $bg_c1;
		@include radius(999px);
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1, 4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
	}
}
.aside-box__list,
.wpp-list,
.wp-widget-group__inner-blocks {
	width: 90%;
	@include m-a;
}

// WordPress Popular Posts用
.wpp-list {
	counter-reset: popular-sidenum;
	li {
		position: relative;
		margin-bottom: 0 !important;
		&::before {
			position: absolute;
			top: .65em;
			left: 0;
			content: "";
			width: 1.75em;
			height: 1.75em;
			@include radius(50%);
		}
		&:nth-of-type(1)::before {
			background: $num_c1;
		}
		&:nth-of-type(2)::before {
			background: $num_c2;
		}
		&:nth-of-type(3)::before {
			background: $num_c3;
		}
		a {
			color: $txt_c;
			@include dec-none;
			display: block;
			padding: 0.75em 0.25em 0.75em 2.5em;
			position: relative;
			border-bottom: 1px solid $l-gray;
			@include media-breakpoint-up(lg) {
				font-size: 0.94em;
			}
			&::after {
				position: absolute;
				top: .55em;
				left: .45em;
				counter-increment: popular-sidenum;
				content: counter(popular-sidenum);
				@include f-family(font02);
				@include f-w(500);
				color: $white;
				font-size: 1.14em;
			}
			&::before {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 0;
				height: 1px;
				@include transition;
				background: $main_c;
			}
			&:hover {
				&::before {
					width: 100%;
				}
			}
		}
	}
}

// select-box
.select-display {
	margin-top: 1rem;
	width: 100%;
	box-sizing: border-box;
	border: 1px solid $m-gray;
	padding: 1em;
	position: relative;
	&__btn {
		display: block;
		position: absolute;
		top: calc(50% - 16px);
		right: 1rem;
		width: 32px;
		height: 32px;
		@include transition;
		i {
			@include f-size(32);
			@include line-h(1);
			color: $gray;
		}
	}
	&.is-active {
		.select-display__btn {
			transform: rotate(180deg);
		}
	}
	.is-text {
		display: block;
		width: 85%;
	}
}

.select-list {
	&.is-active {
		position: relative;
		top: -1px;
		width: 100%;
		background: $white;
		border: 1px solid $m-gray;
		padding: 0.75em 1em 1.25em;
		z-index: z-index(module, part02);
	}
}
.aside-box__list.is-category,
.wp-block-categories-list {
	@include flex-column;
	li {
		a {
			color: $txt_c;
			@include dec-none;
			display: block;
			padding: 0.75em 0.5em 0.75em 1.5em;
			position: relative;
			border-bottom: 1px solid $l-gray;
			&::before {
				position: absolute;
				top: 1.3em;
				left: 0;
				content: "";
				width: 0.6em;
				height: 0.6em;
				@include radius(50%);
				background: $main_c;
			}
			&::after {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 0;
				height: 1px;
				@include transition;
				background: $main_c;
			}
			&:hover {
				&::after {
					width: 100%;
				}
			}
		}
	}
}
.aside-box__list.is-recent,
.wp-block-archives-list,
.wp-block-latest-posts__list {
	li {
		a {
			color: $txt_c;
			@include dec-none;
			display: block;
			padding: 0.75em 0.5em 0.75em 0;
			position: relative;
			border-bottom: 1px solid $l-gray;
			@include media-breakpoint-up(lg) {
				font-size: 0.94em;
			}
			&::before {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 0;
				height: 1px;
				@include transition;
				background: $main_c;
			}
			&:hover {
				&::before {
					width: 100%;
				}
			}
		}
	}
}

.tag-cloud-link {
	font-size: 1rem !important;
	color: $txt_c;
	padding-left: 1.5rem;
	margin-right: 0.5rem;
	padding-bottom: 0.3em;
	position: relative;
	@include icon(tag, before);
	@include dec-none;
	@include transition;
	&::before {
		position: absolute;
		top: 0.6em;
		left: 0.2rem;
		font-size: 0.875em;
		color: $gray;
		@include dec-none;
		@include transition;
	}
	&::after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 0;
		height: 1px;
		background: $main_c;
		@include transition;
	}
	&:hover {
		color: $main_c;
		&::before {
			color: $main_c;
		}
		&::after {
			width: 100%;
		}
	}
}

/* calendar
------------------------------------*/
#wp-calendar {
	border-collapse: separate;
	width: 100%;
}
.calendar_wrap {
	padding: 0.5rem 0.75rem;
	border: 1px solid $m-gray;
}
.wp-calendar {
	&-table {
		//テーブル
		margin-bottom: 0.75rem;
		caption {
			padding: 0.3rem 2%;
			@include f-w(500);
		}
		& thead {
			th {
				text-align: center;
				padding: 6px;
				width: auto;
				color: $gray;
				@include f-w(500);
			}
		}
		td {
			text-align: center;
			background: $p-gray;
			line-height: 2;
			border: 2px solid $white;
			font-size: 0.94em;
			& a {
				background-color: $main_c;
				color: $white;
				@include transition;
				display: block;
				@include dec-none;
				&:hover {
					background-color: $sub_c;
				}
			}
			&.pad {
				background: transparent;
			}
			&#today {
				background: $gray;
				color: $white;
			}
		}
	}
	&-nav {
		@include flex-between;
		width: 100%;
		padding-bottom: 0.3rem;
		a {
			display: block;
			color: $txt_c;
			padding: 0.2em 1rem;
			background-color: $p-gray;
			@include dec-none;
			@include transition;
			&:hover {
				background-color: $main_c;
				color: $white;
			}
		}
	}
}

/*--------------------------------------------------------------------/
	search-form
/--------------------------------------------------------------------*/
.wp-block-search__inside-wrapper {
	position: relative;
	width: 100%;
	border: 1px solid $m-gray !important;
	padding: 0 !important;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.wp-block-search__input {
	height: 44px;
	padding: 0 10px !important;
	outline: 0;
	background: $white;
	appearance: none;
	border: none;
	width: calc(100% - 50px);
}

.wp-block-search__button {
	margin: 0;
	height: 44px !important;
	width: 50px !important;
	padding: 2px !important;
	background: $main_c !important;
	font-size: 18px !important;
	vertical-align: bottom !important;
	color: $white !important;
	@include transition;
	border: none !important;
	text-align: center;
	cursor: pointer !important;
	&:hover {
		color: $white !important;
		background-color: $hover_c !important;
	}
}

/*--------------------------------------------------------------------/
	archives
/--------------------------------------------------------------------*/
.wp-block-archives-dropdown {
	position: relative;
	margin-top: 1em;
	//@include icon(arrow1_bottom, after);
	//&::after {
	//	position: absolute;
	//	top: 0.5em;
	//	right: 0.5em;
	//	@include line-h(1);
	//	color: $gray;
	//	@include f-size(21);
	//}
	& > label {
		display: none !important;
	}
	select {
		position: relative;
		width: 100% !important;
	}
}

select[name*="archive-dropdown"] {
	position: relative;
	width: 90% !important;
	display: block;
	@include m-a;
}
