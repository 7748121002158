@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	service
/--------------------------------------------------------------------*/
// service-intro
.service-intro {
	&__inner {
		@include flex-column;
		row-gap: clamp(2em, 3vw, 3em);
	}
	&__txt {
		@include f-s_xxs(0.94, 6);
		@include media-breakpoint-up(md) {
			text-align: center;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(22);
		}
	}
}

.service-intro__detail {
	padding: clamp(1.5em, 3vw, 3em) clamp(1.5em, 5%, 5em) clamp(5em, 7vw, 7em);
	@include radius_m;
	@include bg-gray;
	@include media-breakpoint-up(md) {
		padding: clamp(2em, 3vw, 3em) clamp(1.5em, 8%, 6em) clamp(2em, 4vw, 4em);
	}
	@include media-breakpoint-down(sm) {
		margin-bottom: clamp(1em, 3vw, 6em);
	}
	&__grid {
		@include media-breakpoint-up(md) {
			display: grid;
			grid-template-columns: 1fr 30%;
			grid-template-rows: auto 1fr;
			gap: 0 4%;
		}
		@include media-breakpoint-up(lg) {
			grid-template-columns: 1fr 20.83%;
			gap: 0 7.7%;
		}
		@include media-breakpoint-down(md) {
			position: relative;
		}
	}
	&__tit {
		position: relative;
		padding: 0.5em 1em;
		margin-bottom: clamp(1em, 1.5vw, 1.5em);
		@include radius;
		color: $white;
		background: $sub_c;
		text-align: center;
		@include f-w(700);
		@include f-s_xxs(1, 10);
		@include media-breakpoint-up(md) {
			margin-top: 1em;
			@include f-s_md(1.5, 10);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(36);
		}
		b {
			@include f-w(700);
			color: $other_c4;
			padding: 0 0.3em;
		}
		@include media-breakpoint-up(md) {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
		}
		&::after {
			@include media-breakpoint-up(md) {
				position: absolute;
				left: calc(100% - 1px);
				top: calc(50% - 0.35em);
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0.35em 0 0.35em 0.6em;
				border-color: transparent transparent transparent $sub_c;
			}
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			grid-column: 1 / 2;
			grid-row: 2 / 3;
			@include line-h(2);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
	}
	&__img {
		@include media-breakpoint-up(md) {
			grid-column: 2 / 3;
			grid-row: 1 / 3;
		}
		@include media-breakpoint-down(sm) {
			position: absolute;
			top: calc(100% - 2vw);
			right: -5%;
			width: clamp(90px, 20%, 200px);
		}
	}
}

// service-list
.service-list {
	padding-top: clamp(6em, 9vw, 9em);
	@include flex-column;
	row-gap: clamp(5em, 9vw, 9em);
}

.service-box {
	position: relative;
	padding-bottom: clamp(3em, 4vw, 4em);
	&::before {
		position: absolute;
		bottom: 0;
		content: "";
		width: 100%;
		height: 80%;
	}
	&:nth-of-type(odd) {
		&::before {
			background: $main_c;
			right: 0;
			@include media-breakpoint-up(md) {
				width: 97.5%;
				border-top-left-radius: clamp(8px,1.5vw,30px);
				border-bottom-left-radius: clamp(8px,1.5vw,30px);
			}
			@include media-breakpoint-up(full) {
				width: calc(50% + 860px);
			}
		}
		.service-box__inner {
			@include media-breakpoint-up(md) {
				padding: clamp(2em, 3vw, 3em) 0 clamp(2em, 4vw, 4em) clamp(1.5em, 8%, 6em);
				&::before {
					left: 0;
				}
			}
		}
		.service-box__txt__tit {
			border-color: $sub_c;
		}
	}
	&:nth-of-type(even) {
		&::before {
			left: 0;
			background: $other_c4;
			@include media-breakpoint-up(md) {
				width: 97.5%;
				border-top-right-radius: clamp(8px,1.5vw,30px);
				border-bottom-right-radius: clamp(8px,1.5vw,30px);
			}
			@include media-breakpoint-up(full) {
				width: calc(50% + 860px);
			}
		}
		.service-box__inner {
			@include media-breakpoint-up(md) {
				flex-direction: row-reverse;
				padding: clamp(2em, 3vw, 3em) clamp(2em, 4vw, 4em) clamp(1.5em, 8%, 6em) 0;
				&::before {
					right: 0;
				}
			}
		}
		.service-box__txt__tit {
			border-color: $point_c;
		}
	}
}

.service-box {
	&__inner {
		position: relative;
		display: flex;
		width: 100%;
		padding: clamp(1.5em, 3vw, 3em) clamp(1.5em, 5%, 5em) clamp(2em, 4vw, 4em);
		z-index: z-index(module, part02);
		@include media-breakpoint-up(md) {
			column-gap: 4%;
			padding: clamp(2em, 3vw, 3em) clamp(1.5em, 8%, 6em) clamp(2em, 4vw, 4em);
		}
		@include media-breakpoint-up(xl) {
			column-gap: 8.89%;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column-reverse;
			row-gap: clamp(1.5rem, 2.5vw, 2rem);
			background: $white;
			@include radius_m;
			box-shadow: 0 0 10px rgba($black, 0.1);
		}
		&::before {
			@include media-breakpoint-up(md) {
				position: absolute;
				top: 0;
				content: "";
				width: 80%;
				height: 100%;
				background: $white;
				@include radius_m;
				box-shadow: 0 0 10px rgba($black, 0.1);
				z-index: -1;
			}
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			width: 54%;
		}
		@include media-breakpoint-up(xl) {
			width: 60%;
		}
		&__tit {
			@include f-s_xxs(1.14, 12);
			@include f-w(700);
			padding-bottom: clamp(0.5em, 0.75vw, 0.75em);
			margin-bottom: clamp(0.75em, 1vw, 1em);
			border-bottom: 0.3em dotted $gray;
			@include media-breakpoint-up(md) {
				@include f-s_md(1.14, 10);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(32);
			}
		}
		&__txt {
			@include media-breakpoint-up(lg) {
				@include line-h(2);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(18);
			}
		}
	}
	&__img {
		@include media-breakpoint-up(md) {
			width: 42%;
		}
		@include media-breakpoint-up(xl) {
			width: 31.11%;
		}
		&__inner {
			@include aspect-img;
			@include radius_s;
			overflow: hidden;
		}
	}
}
