@use "../_mixins/break-points" as *;
@use "../base" as *;

/*--------------------------------------------------------------------/
	breadcrumb
/--------------------------------------------------------------------*/
.breadcrumb {
  padding:0 0 clamp(2em,3vw,3em);
  &__list {
    @include flex-wrap;
    font-size: 0.94em;
    row-gap: .3em;
    & .item-home {
      font-size: 1.2em;
    }
    li {
      position: relative;
      @include line-h(1.2);
      &:not(:last-child) {
        padding-right: 1.5rem;
        @include icon(arrow1_right,after);
        &::after {
          position: absolute;
          right:0.3rem;
          top:50%;
          transform: translateY(-50%);
        }
      }
    }
    a {
      color: $gray;
      @include transition;
      vertical-align: top;
      &:first-child {
        @include dec-none;
      }
      &:hover {
        color: $hover_c;
      }
    }
  }
}
