//===================================================
//	color
//===================================================
// メインカラー
$main_c:    #54BA00;//メインカラー
$sub_c:     #4CAE47;//サブカラー
$point_c:   #E86607;//ポイントカラー
$hover_c:   #12A40A;//ホバーカラー
$txt_c:     #202020;//テキストカラー
$link_c:    $main_c;//リンク基本カラー
$point_ch:  #ed4d0e;//ポイントカラーホバー時

// other color
$other_c1: #F2E700;//アザーカラー1
$other_c2: #F2FBEC;//アザーカラー2
$other_c3: #0E9507;//アザーカラー3
$other_c4: #FDD035;//アザーカラー4
$other_c5: #d4e6c8;//アザーカラー5
$other_c6: #f5b98e;//アザーカラー6
$other_c7: #ed8f1c;//アザーカラー7
$other_c8: #92D459;//アザーカラー8

//line color
$line_c:   #00B900;//ラインカラー

//number color
$num_c1:   #DAB807;//ナンバー1
$num_c2:   #A7B5BE;//ナンバー2
$num_c3:   #AF6834;//ナンバー3

// backgraound
$bg_c1:    #FFF9E5;//バックカラー1
$bg_c2:    #F0F3F4;//バックカラー2
$bg_c3:    #D9E8FF;//バックカラー3
$bg_c4:    #D7F1FB;//バックカラー4
$bg_c5:    #F2F7FF;//バックカラー5
$bg_c6:    #E4E6E7;//バックカラー6
$bg_c7:    #EDFFE1;//バックカラー7
$bg_c8:    #FEFDE6;//バックカラー8
$bg_c9:    #FDF0E6;//バックカラー9
$bg_c10:   #FDF0E6;//バックカラー10
$bg_c11:   #e3fcd2;//バックカラー11

// mono tone
$white:     #fff;   //ホワイト
$o-white:   #fcfcfc;//オフホワイト
$p-gray:    #F8F9FA;   //ペールグレー
$l-gray:    #D9D9D9;   //ライトグレー
$m-gray:    #C9CED2;   //ミディアムグレー
$gray:      #979DA4;   //グレー
$dl-gray:   #6F757B;   //ダルグレー
$d-gray:    #4B4D4E;   //ダークグレー
$b-gray:    #232729;   //ブラックグレー
$black:     #000;   //ブラック

// color tone
$red:       #E52323;//レッド
$red2:      #F24949;//レッド2
$orange:    #ff8b2d;//オレンジ
$yellow:    #ffcf27;//イエロー
$y-green:   #01a63d;//イエローグリーン
$green:     #5d9e36;//グリーン
$s-blue:    #78CAF2;//スカイブルー
$l-blue:    #578FE4;//ライトブルー
$blue:      #2b74e0;//ブル-
$violet:    #425c93;//バイオレット
$purple:    #5c0c7a;//パープル
$pink:      #ca6a51;//ピンク

